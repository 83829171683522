// figma
@import './../../styles/figma/tokens-functional.scss';

//text
$base-text-color: $black-pearl;
$color-text-1: $base-text-color;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

// figma
$project--main-color: $figma-color--project-color;

//links
$link-default-color: $figma--links--link_normal--color;
$link-hover-color: $figma--links--link_hover--color;
$link-active-color: $figma--links--link_active--color;
$link-visited-color: $figma--links--link_visited--color;

//pseudo links (functional page)
$link--fn-default-color: $figma--pseudo-links--link_normal--color;
$link--fn-hover-color: $figma--pseudo-links--link_hover--color;
$link--fn-active-color: $figma--pseudo-links--link_active--color;
$link--fn-visited-color: $figma--pseudo-links--link_visited--color;

//buttons
$btn--bg: $figma--buttons--button_normal--bg;
$btn--bg-hover: $figma--buttons--button_hover--bg;
$btn--bg-active: $figma--buttons--button_active--bg;
$btn--bg-disabled: $figma--buttons--button_disabled--bg;
//
$btn--color: $figma--buttons--button_normal--color;
$btn--color-hover: $figma--buttons--button_hover--color;
$btn--color-active: $figma--buttons--button_active--color;
$btn--color-disabled: $figma--buttons--button_disabled--color;
// additional
$btn--border-color: $figma--buttons--button_normal--border;
$btn--border-color-hover: $figma--buttons--button_hover--border;
$btn--border-color-active: $figma--buttons--button_active--border;
$btn--border-color-disabled: $figma--buttons--button_disabled--border;

// figma
//checkbox
$checkbox--color: $figma--checkboxes--checkbox_normal--color;
$checkbox--color-hover: $figma--checkboxes--checkbox_hover--color;
$checkbox--color-checked: $figma--checkboxes--checkbox_active--color;
$checkbox--color-partly: $figma--checkboxes--checkbox_party--color;
// additional
$checkbox--border-color: $figma--checkboxes--checkbox_normal--border;
$checkbox--border-color-hover: $figma--checkboxes--checkbox_hover--border;
$checkbox--border-color-checked: $figma--checkboxes--checkbox_active--border;
$checkbox--border-color-partly: $figma--checkboxes--checkbox_party--border;
//
$checkbox--bg: $figma--checkboxes--checkbox_normal--bg;
$checkbox--bg-hover: $figma--checkboxes--checkbox_hover--bg;
$checkbox--bg-checked: $figma--checkboxes--checkbox_active--bg;
$checkbox--bg-partly: $figma--checkboxes--checkbox_party--bg;

// xls, zoom, analysis buttons
$tools--color: $figma--icons--icon_normal--color;
$tools--color-hover: $figma--icons--icon_hover--color;
$tools--color-active: $figma--icons--icon_active--color;

// tooltips
$t_tooltips--bg-color: $color-ironside-gray;
$t_tooltips--color: $color-white;

@if ($contrast) {
	$base-text-color: $color-white;
	$error-text-color: #ff0000;
	$error-form-bg: #fff;
	$link-default-color: $base-text-color;
	$link-hover-color: $color-carrot-orange;
	$link-visited-color: #252525;
	$btn--bg: #ededed;
	$btn--bg-hover: #484848;
	$btn--bg-active: #252525;
	$btn--color: #656565;
	$btn--color-hover: $color-white;
	$btn--color-active: $color-white;
	$tools--color: $color-ironside-gray;
	$tools--color-hover: $color-black;
	$tools--color-active: $color-carrot-orange;
}
