// tzar-core/src/gulp/utils/webfont-template.scss - файл-шаблон для генерации иконочного шрифта
// tzar-template/src/styles/webfont.scss - генерируемый файл шрифта, генерируется автоматически при сборке!

$icon_font_name:"webfont";

$webfont-icon--analyse: "\E001";
$webfont-icon--arrow-left: "\E002";
$webfont-icon--arrow-right: "\E003";
$webfont-icon--books: "\E004";
$webfont-icon--box-add: "\E005";
$webfont-icon--camera: "\E006";
$webfont-icon--checkmark: "\E007";
$webfont-icon--clipboard: "\E008";
$webfont-icon--clock: "\E009";
$webfont-icon--close: "\E00A";
$webfont-icon--close_2: "\E00B";
$webfont-icon--cookies-policy: "\E00C";
$webfont-icon--download: "\E00D";
$webfont-icon--external-link: "\E00E";
$webfont-icon--eye: "\E00F";
$webfont-icon--facebook: "\E010";
$webfont-icon--file-empty: "\E011";
$webfont-icon--file-excel: "\E012";
$webfont-icon--file-pdf: "\E013";
$webfont-icon--file-text: "\E014";
$webfont-icon--file-word: "\E015";
$webfont-icon--file-zip: "\E016";
$webfont-icon--foursquare: "\E017";
$webfont-icon--google-plus: "\E018";
$webfont-icon--google-plus_2: "\E019";
$webfont-icon--histogram: "\E01A";
$webfont-icon--home: "\E01B";
$webfont-icon--i-add: "\E01C";
$webfont-icon--i-arrow-breadcrumbs: "\E01D";
$webfont-icon--i-arrow-nav-fill: "\E01E";
$webfont-icon--i-arrow-nav: "\E01F";
$webfont-icon--i-button-xls: "\E020";
$webfont-icon--i-close: "\E021";
$webfont-icon--i-dcenter: "\E022";
$webfont-icon--i-del: "\E023";
$webfont-icon--i-download: "\E024";
$webfont-icon--i-feedback: "\E025";
$webfont-icon--i-gear: "\E026";
$webfont-icon--i-hist: "\E027";
$webfont-icon--i-home: "\E028";
$webfont-icon--i-inst: "\E029";
$webfont-icon--i-instagram: "\E02A";
$webfont-icon--i-interactive: "\E02B";
$webfont-icon--i-line: "\E02C";
$webfont-icon--i-main-arrow: "\E02D";
$webfont-icon--i-map: "\E02E";
$webfont-icon--i-menu: "\E02F";
$webfont-icon--i-pdf-double-page: "\E030";
$webfont-icon--i-pdf-page: "\E031";
$webfont-icon--i-pdf-tools: "\E032";
$webfont-icon--i-pdf: "\E033";
$webfont-icon--i-prevrep: "\E034";
$webfont-icon--i-print: "\E035";
$webfont-icon--i-search: "\E036";
$webfont-icon--i-share: "\E037";
$webfont-icon--i-up: "\E038";
$webfont-icon--i-youtube: "\E039";
$webfont-icon--instagram: "\E03A";
$webfont-icon--lang-en: "\E03B";
$webfont-icon--lang-ru: "\E03C";
$webfont-icon--library: "\E03D";
$webfont-icon--line: "\E03E";
$webfont-icon--link-arrow: "\E03F";
$webfont-icon--link-more: "\E040";
$webfont-icon--linkedin: "\E041";
$webfont-icon--location: "\E042";
$webfont-icon--mail: "\E043";
$webfont-icon--menu: "\E044";
$webfont-icon--microphone: "\E045";
$webfont-icon--next: "\E046";
$webfont-icon--pdf-double-page: "\E047";
$webfont-icon--pdf-page: "\E048";
$webfont-icon--pdf: "\E049";
$webfont-icon--phone: "\E04A";
$webfont-icon--prev: "\E04B";
$webfont-icon--printer: "\E04C";
$webfont-icon--reload: "\E04D";
$webfont-icon--reload_2: "\E04E";
$webfont-icon--report: "\E04F";
$webfont-icon--search: "\E050";
$webfont-icon--share: "\E051";
$webfont-icon--tools: "\E052";
$webfont-icon--tooltip-kmg: "\E053";
$webfont-icon--tooltip: "\E054";
$webfont-icon--tree: "\E055";
$webfont-icon--twitter: "\E056";
$webfont-icon--up: "\E057";
$webfont-icon--users: "\E058";
$webfont-icon--vector: "\E059";
$webfont-icon--vk: "\E05A";
$webfont-icon--vk_2: "\E05B";
$webfont-icon--xls: "\E05C";
$webfont-icon--youtube: "\E05D";
$webfont-icon--youtube_2: "\E05E";
$webfont-icon--zoom-kmg: "\E05F";

@mixin webfont-icon($icon-code, $after-or-before: 'before') {
	&:#{$after-or-before} {
		content: #{'"' + $icon-code + '"'};
		display: inline-block;
		font-family: $icon_font_name;
		font-style: normal;
		line-height: 1;
		color: currentColor;
		-webkit-font-smoothing: antialiased;
		speak: none;
	}
}

@font-face {
	font-family: $icon_font_name;
	src: url('/fonts/webfont/webfont.woff') format('woff'),
	url('/fonts/webfont/webfont.woff2') format('woff2'),
	url('/fonts/webfont/webfont.svg#webfont') format('svg');
}

.webfont-icon {
	font-family: $icon_font_name;
	display: inline-block;
	vertical-align: middle;
	font-style: normal;
	speak: none;
	color: currentColor;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.webfont-icon--analyse:before {
	content: '\E001';
}

.webfont-icon--arrow-left:before {
	content: '\E002';
}

.webfont-icon--arrow-right:before {
	content: '\E003';
}

.webfont-icon--books:before {
	content: '\E004';
}

.webfont-icon--box-add:before {
	content: '\E005';
}

.webfont-icon--camera:before {
	content: '\E006';
}

.webfont-icon--checkmark:before {
	content: '\E007';
}

.webfont-icon--clipboard:before {
	content: '\E008';
}

.webfont-icon--clock:before {
	content: '\E009';
}

.webfont-icon--close:before {
	content: '\E00A';
}

.webfont-icon--close_2:before {
	content: '\E00B';
}

.webfont-icon--cookies-policy:before {
	content: '\E00C';
}

.webfont-icon--download:before {
	content: '\E00D';
}

.webfont-icon--external-link:before {
	content: '\E00E';
}

.webfont-icon--eye:before {
	content: '\E00F';
}

.webfont-icon--facebook:before {
	content: '\E010';
}

.webfont-icon--file-empty:before {
	content: '\E011';
}

.webfont-icon--file-excel:before {
	content: '\E012';
}

.webfont-icon--file-pdf:before {
	content: '\E013';
}

.webfont-icon--file-text:before {
	content: '\E014';
}

.webfont-icon--file-word:before {
	content: '\E015';
}

.webfont-icon--file-zip:before {
	content: '\E016';
}

.webfont-icon--foursquare:before {
	content: '\E017';
}

.webfont-icon--google-plus:before {
	content: '\E018';
}

.webfont-icon--google-plus_2:before {
	content: '\E019';
}

.webfont-icon--histogram:before {
	content: '\E01A';
}

.webfont-icon--home:before {
	content: '\E01B';
}

.webfont-icon--i-add:before {
	content: '\E01C';
}

.webfont-icon--i-arrow-breadcrumbs:before {
	content: '\E01D';
}

.webfont-icon--i-arrow-nav-fill:before {
	content: '\E01E';
}

.webfont-icon--i-arrow-nav:before {
	content: '\E01F';
}

.webfont-icon--i-button-xls:before {
	content: '\E020';
}

.webfont-icon--i-close:before {
	content: '\E021';
}

.webfont-icon--i-dcenter:before {
	content: '\E022';
}

.webfont-icon--i-del:before {
	content: '\E023';
}

.webfont-icon--i-download:before {
	content: '\E024';
}

.webfont-icon--i-feedback:before {
	content: '\E025';
}

.webfont-icon--i-gear:before {
	content: '\E026';
}

.webfont-icon--i-hist:before {
	content: '\E027';
}

.webfont-icon--i-home:before {
	content: '\E028';
}

.webfont-icon--i-inst:before {
	content: '\E029';
}

.webfont-icon--i-instagram:before {
	content: '\E02A';
}

.webfont-icon--i-interactive:before {
	content: '\E02B';
}

.webfont-icon--i-line:before {
	content: '\E02C';
}

.webfont-icon--i-main-arrow:before {
	content: '\E02D';
}

.webfont-icon--i-map:before {
	content: '\E02E';
}

.webfont-icon--i-menu:before {
	content: '\E02F';
}

.webfont-icon--i-pdf-double-page:before {
	content: '\E030';
}

.webfont-icon--i-pdf-page:before {
	content: '\E031';
}

.webfont-icon--i-pdf-tools:before {
	content: '\E032';
}

.webfont-icon--i-pdf:before {
	content: '\E033';
}

.webfont-icon--i-prevrep:before {
	content: '\E034';
}

.webfont-icon--i-print:before {
	content: '\E035';
}

.webfont-icon--i-search:before {
	content: '\E036';
}

.webfont-icon--i-share:before {
	content: '\E037';
}

.webfont-icon--i-up:before {
	content: '\E038';
}

.webfont-icon--i-youtube:before {
	content: '\E039';
}

.webfont-icon--instagram:before {
	content: '\E03A';
}

.webfont-icon--lang-en:before {
	content: '\E03B';
}

.webfont-icon--lang-ru:before {
	content: '\E03C';
}

.webfont-icon--library:before {
	content: '\E03D';
}

.webfont-icon--line:before {
	content: '\E03E';
}

.webfont-icon--link-arrow:before {
	content: '\E03F';
}

.webfont-icon--link-more:before {
	content: '\E040';
}

.webfont-icon--linkedin:before {
	content: '\E041';
}

.webfont-icon--location:before {
	content: '\E042';
}

.webfont-icon--mail:before {
	content: '\E043';
}

.webfont-icon--menu:before {
	content: '\E044';
}

.webfont-icon--microphone:before {
	content: '\E045';
}

.webfont-icon--next:before {
	content: '\E046';
}

.webfont-icon--pdf-double-page:before {
	content: '\E047';
}

.webfont-icon--pdf-page:before {
	content: '\E048';
}

.webfont-icon--pdf:before {
	content: '\E049';
}

.webfont-icon--phone:before {
	content: '\E04A';
}

.webfont-icon--prev:before {
	content: '\E04B';
}

.webfont-icon--printer:before {
	content: '\E04C';
}

.webfont-icon--reload:before {
	content: '\E04D';
}

.webfont-icon--reload_2:before {
	content: '\E04E';
}

.webfont-icon--report:before {
	content: '\E04F';
}

.webfont-icon--search:before {
	content: '\E050';
}

.webfont-icon--share:before {
	content: '\E051';
}

.webfont-icon--tools:before {
	content: '\E052';
}

.webfont-icon--tooltip-kmg:before {
	content: '\E053';
}

.webfont-icon--tooltip:before {
	content: '\E054';
}

.webfont-icon--tree:before {
	content: '\E055';
}

.webfont-icon--twitter:before {
	content: '\E056';
}

.webfont-icon--up:before {
	content: '\E057';
}

.webfont-icon--users:before {
	content: '\E058';
}

.webfont-icon--vector:before {
	content: '\E059';
}

.webfont-icon--vk:before {
	content: '\E05A';
}

.webfont-icon--vk_2:before {
	content: '\E05B';
}

.webfont-icon--xls:before {
	content: '\E05C';
}

.webfont-icon--youtube:before {
	content: '\E05D';
}

.webfont-icon--youtube_2:before {
	content: '\E05E';
}

.webfont-icon--zoom-kmg:before {
	content: '\E05F';
}

