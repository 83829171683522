.l-layout-wrapper--1 {
	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $dark-cerulean;
			background-image: url('/images/display/image-back.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center right;
			z-index: -1;
		}
		// (!)
		// @include rem(padding, 0 67px 0 88px);
		// @include rem(margin-bottom, 61px);
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		@include rem(padding, 10px 0);
		justify-content: space-between;

		&__left {
			display: flex;
			justify-content: space-between;
		}

		&__menu {
			margin-right: 27px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		&__logo {
			flex-shrink: 0;
			// margin-left: 73px;
			// (!)
			margin-left: -104px;
			margin-top: -1px;
		}

		&__title {
			flex-shrink: 0;
			flex-grow: 0;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
		}
	}

	.l-header-top-nav {
		display: flex;

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.l-page {
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}

		// (!)
		// &__content {
		// 	background: red;
		// 	width: calc(100% - 320px);
		// 	width: $left-col-width + $center-col-width;
		// }

		// @include breakpoint(lg) {
		// 	flex-direction: column;

		// 	&__content {
		// 		width: 100%;

		// 		.browser-ie & {
		// 			height: 100%; // способ решить большое белое пространство в ие
		// 		}

		// 	}

		// 	// &__sidebar--right {
		// 	// 	width: 500px;
		// 	//     margin: 0 auto;
		// 	//     text-align: center;
		// 	// }
		// }
	}

	.l-page-nav {
		min-height: auto !important;
		height: auto !important;
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			@include rem(margin-left, 30px);
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__lang {
			@include rem(margin-left,52px);
		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		position: relative;
		display: flex;
		z-index: 11;

		// (!)
		// @include rem(padding, 8px 88px);
		@include rem(padding-top, 8px);
		@include rem(padding-bottom, 8px);

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $dark-cerulean;
			background-image: url('/images/display/image-back.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center right;
		}

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			z-index: 1;
		}

		&__nav {
			text-align: right;
		}

		&__social {
			margin-left: -6vw;
		}
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			// (!)
			.l-header {
				// @include rem(padding, 12px 24px);
				// @include rem(height, 50px);
				padding-bottom: 0;
			}

			.l-header-top-line {
				@include rem(padding, 12px 0);
			}

			.l-footer {
				// @include rem(padding, 17px 25px);
				@include rem(padding-top, 17px);
				@include rem(padding-bottom, 17px);

				&__social {
					margin-left: -12vw;
				}
			}
			// (!)

			.l-header-top-line__logo {
				// display: block;
				@include rem(margin-top, -6px);
				// @include rem(margin-left, 0);
			}

			.l-header-top-nav__logo {
				display: none;
			}

			.l-buttons-area {
				.l-buttons-area__buttons-panel {
					@include rem(margin-top, -1px);
				}

				&__item + .l-buttons-area__item {
					@include rem(margin-left, 23px);
				}
			}

			// (!)
			// .l-page__content {
			// 	padding-right: 50px;
			// 	width: calc(100% - 220px);
			// 	background: red;
			// }

			// .aside--right {
			// 	background: red;
			// 	width: 220px;
			// 	right: -220px;
			// }
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-header {
				// @include rem(padding, 10px 16px 10px 51px);
				// @include rem(height, 45px);
				background-image: none;
			}

			.l-header-top-line__menu {
				display: none;
			}

			.l-header-top-line__logo {
				@include rem(margin-top, -4px);
				margin-left: -70px;
			}

			.l-header-top-line__title {
				max-width: 70px;
			}

			.l-buttons-area {
				.l-buttons-area__mobile-nav-panel {
					display: block;
					position: absolute;
					@include rem(top, 13px);
					@include rem(left, 16px);
					@include rem(margin, 0);
				}

				.l-buttons-area__search,
				.l-buttons-area__pdf,
				.l-buttons-area__lang {
					display: none;
				}
			}

			.l-footer {
				@include rem(padding-top, 28px);
				@include rem(padding-bottom, 28px);

				&__social {
					margin-left: -16vw;
				}
			}

			.l-footer__copyright {
				// @include rem(margin-bottom, 10px);
			}

			.l-page {
				flex-direction: column;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
				padding-left: 0;

				&-section--adaptive-menu {
					display: none;
				}
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-nav {
				display: none;
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-line__logo {
				margin-left: auto;
			}

			.l-footer {
				background-image: none;
				@include rem(padding-top, 18px);
				@include rem(padding-bottom, 18px);

				&__social {
					margin-left: 0;
					@include rem(margin-bottom, 15px);
				}

				&__copyright {
					@include rem(margin-bottom, 18px);
				}

				&__inner {
					flex-direction: column;
				}
			}
		}
	}
}
