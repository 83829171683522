.b-logo {
	display: block;
	width: 208px;//241px;
	height: 48px;

	&__image {
		width: 100%;
		height: 100%;
	}

	@if ($responsive) {

		@include breakpoint(md) {
			@include rem(width, 132px);
			@include rem(height, 32px);
		}

		@include breakpoint(sm) {
			@include rem(width, 123px);
			@include rem(height, 30px);
		}
	}
}

.logo-svg {
	width: 100%;
	height: 100%;
}

