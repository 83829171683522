$header-bg: $color-white;
$body-bg: $color-white;
$footer-bg: $color-white;

$layout-content-margin-l: 12vw;
$layout-content-padding-r: 96px;

@if ($contrast) {
	$header-bg: #656565;
	$body-bg: #656565;
	$footer-bg: #656565;
}

body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.is-lock {
	.browser-ie & {
		height: 100%;
	}
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;

	&__header {
		flex: none;
		@include rem(padding-bottom, 10px);

		// .is-locked & {
		// 	width: calc(100% + 15px);
		// }
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;
	// padding: 0 57px 0 17vw; (!)

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		// зачем убран базис (!)
		flex-basis: $right-col-width;
		width: $right-col-width;
		min-width: $right-col-width;
		// (!)
		padding-left: $grid-gutter-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	&__content--with-left {
		padding-left: 0;
		// max-width: $left-col-width + $center-col-width;
		// width: 100%; (!)
		// width: $left-col-width + $center-col-width;
		// отступ иммитирующий левый сайдбар
		margin-left: $layout-content-margin-l;
		// padding-left: 12vw;
		padding-right: $layout-content-padding-r;
		// (!)
		// overflow: hidden;
		max-width: calc(100% - #{$right-col-width} - #{$layout-content-margin-l});

		@media (max-width: 1400px) {
			max-width: calc(100% - #{$right-col-width} - 61px);
		}

		@include breakpoint(md) {
			max-width: calc(100% - #{$right-col-width--md});
		}

		@include breakpoint(sm) {
			max-width: none;
		}
	}

	&__content--with-right {
		padding-right: 0;
		width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
		max-width: none;
	}

	&__nav {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 46px);
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.l-layout-wrapper__page,
// (!)
.l-layout-wrapper__header,
.l-layout-wrapper__footer {
	padding-left: $layout-padding--md;
	padding-right: $layout-padding--md;
	// padding-left: $layout-padding;
	// padding-right: $layout-padding;
}

.l-layout-wrapper__page {
	@include rem(padding-top, 61px);
}

.content-area {
	min-height: 1px;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
	// margin-left: -150px;

	// @media (max-width: 1600px) {
	// 	margin-left: -100px;
	// }

	// @media (max-width: 1270px) {
	// 	margin-left: -50px;
	// }

	// @media (max-width: 1054px) {
	// 	margin-left: 0;
	// }
}

.l-content--with-right {
	margin-right: -($right-col-width + $layout-content-padding-r);
	margin-left: -($layout-content-margin-l);

	@media (max-width: 1400px) {
		margin-right: -($right-col-width + $grid-gutter-width);
		margin-left: -61px;
	}

	@include breakpoint(md) {
		margin-right: -($right-col-width--md + $grid-gutter-width);
		margin-left: 0px;
	}

	@include breakpoint(sm) {
		margin-right: 0px;
		margin-left: 0px;
	}
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
	width: $right-col-width;
	right: -$right-col-width;
	padding-left: $grid-gutter-width;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

.aside + .aside {
	margin-top: 150px;

	@include breakpoint(sm) {
		margin-top: 0px;
	}
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		// max-width: $site-width;
		max-width: 1727px + $layout-padding--md * 2;
		min-width: $site-min-width;
	}

	// @media (max-width: 1600px) {
	// 	.l-page {
	// 		padding: 0 47px 0 10vw;
	// 	}
	// }

	@media (max-width: 1400px) {
		.l-page__content {
			// (!)
			margin-left: 61px; //бургер + отступ
			padding-right: $grid-gutter-width;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper__footer,
		.l-layout-wrapper__page,
		.l-layout-wrapper__header {
			padding-left: $layout-padding--md;
			padding-right: $layout-padding--md;
		}

		.l-layout-wrapper__page {
			@include rem(padding-top, 30px);
		}

		.l-page {
			&__content {
				// width: calc(100% - 320px);
				// padding-right: 16px;
				// (!)
				// width: auto;
				width: 100%;

				//overflow: hidden;
				margin-left: 0;
				// padding-right: $grid-gutter-width;
				padding-right: 0;
			}

			&__sidebar--right {
				flex-basis: $right-col-width--md;
				width: $right-col-width--md;
				min-width: $right-col-width--md;
				padding-left: $grid-gutter-width;
			}
		}

		.aside--right {
			width: $right-col-width--md;
			right: -$right-col-width--md;
			padding-left: $grid-gutter-width;
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper__header {
			padding-left: 61px; //Бургер
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__footer,
		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-page {
			// padding: 0 16px 20px;

			// &__content {
			// 	width: 100%;
			// 	overflow: hidden;
			// 	background: red;
			// }

			&__sidebar {
				// min-width: 100%;
				// width: 100%;
				// background: red;
			}
		}

		.l-page__content {
			padding-left: 0;
			padding-right: 0;
			@include rem(padding-bottom, 16px);
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	@include breakpoint(xs) {
		.l-page {
			&__nav {
				display: none;
			}
		}
	}
}
