$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок

button {
	border: 0;
	// Для скрипта контролирующего фокус кнопок
	&:focus {
		outline-width: 0;
	}

	&.focus-on:focus {
		outline-width: 1px;
	}
}

%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	color: $btn--color;
	// border: 0;
	background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	// additional
	border: 2px solid $btn--border-color;

	&:hover {
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
		// additional
		border-color: $btn--border-color-hover;
	}

	&:active {
		background-color: $btn--bg-active;
		color: $btn--color-active;
		transform: translateY(1px);
		// additional
		border-color: $btn--border-color-active;
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	// @include font-size(16px);
	// @include rem(line-height, 20px);
	// border: none;
	// color: $white;
	// background: $summer-sky;
	// @include font(300, 'Ubuntu');
	// @include rem(width, 130px);
	// @include rem(height, 48px);
	@include rem(border-radius, 50px);

	// &:hover {
	// 	background: $my-sin;
	// }

	// &:active {
	// 	background: $dark-cerulean;
	// }

	// &:visited {
	// 	background: transparent;
	// 	color: $polo-blue;
	// 	@include rem(border-width, 2px);
	// 	border-style: solid;
	// 	border-color: $polo-blue;;
	// }

	// &:visited {
	// 	background-color: $figma--buttons--button_visited--bg;
	// 	color: $figma--buttons--button_visited--bg;
	// 	// additional
	// 	border-color: $figma--buttons--button_visited--bg;
	// }

	// &:disabled {
	// 	background: $pattens-blue;
	// 	color: $dark-cerulean;
	// }

	&.btn--search {
		@include rem(padding, 7px 47px 6px 46px);
		@include rem(width, 144px);
		@include rem(height, 33px);
	}

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}

	// забрать стили из класса типографики (./figma/tokens-typography.scss)
	@extend .figma-class--btn;
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	color: $btn--color-disabled;
	background-color: $btn--bg-disabled;
	opacity: 0.3;
	cursor: default;
	// additional
	border-color: $btn--border-color-disabled;
	outline: 0;

	&:hover {
		color: $btn--color-disabled;
		background-color: $btn--bg-disabled;
		border-color: $btn--border-color-disabled;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
