$useful-links--btn-bg: $color-ironside-gray;
$useful-links--btn-color: $color-white;
$useful-links--bg: $color-eallery;
$useful-links--link-color: $color-black;
$useful-links--link-color-hover: $color-tapa-gray;

@if ($contrast) {
	$useful-links--btn-bg: $color-light-gray;
	$useful-links--btn-color: $base-text-color;
	$useful-links--bg: $color-light-gray;
	$useful-links--link-color: $color-ironside-gray;
	$useful-links--link-color-hover: $color-mine-shaft;
}

.b-useful-links  {
	@include rem(margin-top, $grid-gutter-width);
	@include rem(border-width, 2px);
	@include rem(border-radius, 25px);
	@include rem(padding, 18px 0 13px);
	border-style: solid;
	border-color: $pattens-blue;
	background: $white-smoke;

	&__link {
		@extend %disable-visited;
		display: block;
		position: relative;
		@include font-size(16px);
		@include rem(padding-top, 7px);
		@include rem(padding-bottom, 7px);
		@include font(400, 'Ubuntu');
		color: $regal-blue;
		text-decoration: none;
		@include rem(margin-bottom, 8px);
		@include rem(padding, 0 24px);
	}
}

.read__more {
	
	@include font(300, 'Ubuntu');
	font-weight: 300;
	@include font-size(14px);
	@include rem(line-height, 20px);
	@include rem(padding, 30px 28px 27px 25px);
	@include rem(border-width, 2px);
	@include rem(border-radius, 25px);
	border-style: solid;
	border-color: $pattens-blue;

	&--link {
		
		background: $white-smoke;
		@include rem(padding, 18px 28px 27px 20px);

		a {
			@include webfont-icon($webfont-icon--link-more);
			@include rem(margin-bottom, 15px);
    		display: block;
			
			&::before {
				@include rem(font-size, 32px);
			}
		}

		p {
			@include font(500, 'Ubuntu');
			@include font-size(16px);
			@include rem(line-height, 22px);
			color: $regal-blue;
			margin-bottom: 0;

			span {
				text-decoration: underline;
				color: $summer-sky;
			}
		}
	}
}