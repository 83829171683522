.inset {
	position: relative;
	color: $color-davys-grey;
	font-size: 14px;
	font-weight: 400;
	border-left: 2px solid currentColor;
	@include rem(padding-left, 15px);

	&--border {
		font-size: 14px;
		line-height: 20px;
		color: $dark-cerulean;
		border: 2px solid $pattens-blue;
		border-radius: 25px;
		padding: 30px 25px;
		@include font(300, 'Ubuntu');
		margin-bottom: 20px;

		p {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--link-external {
		background: $white-smoke;
		border: 2px solid $pattens-blue;
		border-radius: 25px;
		font-size: 16px;
		line-height: 22px;
		@include font(500, 'Ubuntu');
		color: $regal-blue;
		padding: 20px 25px;
		@include webfont-icon($webfont-icon--link-more);
		margin-bottom: 20px;

		&::before {
			color: #27BDEE;
			font-size: 28px;
			margin-bottom: 20px;
		}

		.link--external {
			&::before {
				display: none !important;
			}
		}
		p {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--link {
		margin-bottom: 20px;
		border: 0;
	}
}

.inset-color {
	padding: 30px 50px;
	margin-bottom: 26px;

	p:last-child {
		margin-bottom: 0;
	}

	&--blue {
		background-color: #E1F4FD;
	}

	&--green {
		background-color: #F1F7EC;
	}

	@include breakpoint(sm) {
		padding: 10px 16px;
	}
}
.inset--with-title {
	@include rem(padding, 25px 25px 25px);
	border: 1px solid $color-davys-grey;
	color: $color-davys-grey;

	figcaption {
		font-size: 18px;
		font-weight: bold;
		color: $color-davys-grey;
		position: absolute;
		top: 0;
		@include rem(left, 15px);
		transform: translateY(-50%);
		background: #fff;
		@include rem(padding, 0 10px);
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.inset-image {
	position: relative;

	&__text {
		position: absolute;
		padding: 7px 7px;
		background-color: $color-mountain-mist;
		opacity: 0.7;
		color: $color-black;
		top: 0;
	}
}
