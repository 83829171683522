$lang-selector_multi-button--color: #648DB2;
$lang-selector_multi-button--color-hover: $my-sin;
$lang-selector_multi-button--color-bg: $color-celeste;
$lang-selector_multi-button--color-current: $color-white;
$lang-selector_multi-button--color-current-bg: $color-mine-shaft;

.b-lang--multi-button {
	display: flex;
	margin: 0 -4px;

	.b-lang__item {
		color: $lang-selector_multi-button--color;
		cursor: pointer;
		@include rem(padding, 0 11px);
		text-decoration: none;
		text-transform: uppercase;
		font-size: 22px;
		line-height: 25px;
		@include webfont-icon($webfont-icon--i-line, 'after');
		position: relative;

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 24px;
			color: $color-white;
		}

		&:hover {
			color: $lang-selector_multi-button--color-hover;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	
	.b-lang__item--current {
		display: block;
		text-align: center;
		text-decoration: none;
		color: $lang-selector_multi-button--color-current;
		cursor: default;

		&:hover {
			color: $lang-selector_multi-button--color;
		}
	}

	@if ($responsive) {

		@include breakpoint(md) {

			.b-lang__item {
				@include font-size(20px);
				@include rem(line-height, 23px);
				@include rem(padding, 0 7px);

				&:after {
					@include font-size(22px);
				}
			}
		}

		@include breakpoint(sm) {

			.b-lang__item {
				@include font-size(16px);
				@include rem(line-height, 18px);
				color: $color-air-force-blue;

				&:after {
					@include font-size(18px);
					color: $dark-cerulean;
				}

				&--current {
					color: $dark-cerulean;
				}
			}
		}
	}
}
