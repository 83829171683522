.body--main-page {
	// overflow: hidden;
	.l-layout-wrapper--1 .l-header {
		margin-bottom: 0;
		z-index: 10;
	}

	.l-page {
		padding: 0;

		&__content--wide {
			padding: 0;
			margin: 0;
		}
	}

	.l-layout-wrapper--1 .l-footer {
		display: none;
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 .l-page__content {
			padding: 0;
		}
	}
}

.mainpage {
	// height: 100%;
	overflow: hidden;
	height: calc(100vh - 77px);
	position: relative;
	width: 100vw;
	left: 50%;
	transform: translateX(-50%);

	&__preloader {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		transition: all .3s ease;
		background-color: #004586;

		#video-preloader,
		#video-preloader-mobile,
		#video-preloader-tablet {
			height: 100%;
			width: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}

		#video-preloader-mobile {
			display: none;
		}

		#video-preloader-tablet {
			display: none;
		}

		&.is-hidden {
			opacity: 0;
			visibility: hidden;
			z-index: 0;
		}
	}

	&__preloader.is-hidden  + &__wrapper {
		opacity: 1;
		visibility: visible;

		&::before {
			z-index: 0;
			background-color: transparent;
		}
	}

	&__wrapper {
		height: 100%;
		opacity: 0;
		visibility: hidden;
		display: flex;
		transition: opacity .3s ease, visible .3s ease;

		&::before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $color-white;
			transition: background-color 1s ease, z-index 1.1s ease;
			z-index: 10;
		}

		// &.is-visible {

		// }
	}

	&__item {
		display: flex;
		height: 100%;

		&.is-active {
			// flex: 1 0 auto;
		}
	}

	&__content {
		//width: 0;
		overflow: hidden;
		//transition: all .3s ease;

		&.is-visible {
			//width: 100%;
		}
	}

	&__tab-wrapper {
		background-repeat: no-repeat;
		background-size: 100vw 100vh;//cover;
		height: 100%;
		display: flex;
		justify-content: space-between;
		overflow: hidden;

		&--1 {
			background-image: url('/images/main/bg-production.jpg');

			[lang="en"] & {
				background-image: url('/images/main/bg-production-en.jpg');
			}
		}

		&--2 {
			background-image: url('/images/main/transport.jpg');
		}

		&--3 {
			background-image: url('/images/main/2_8.jpg');
		}

		&--4 {
			background: url('/images/main/slide4.jpg') left/cover no-repeat;
		}
	}

	&__link-wrapper {
		background: #1F8FB5;
		//transition: width .3s ease;
		position: relative;

		&.is-hidden {
			// display: none;
			opacity: 0;
			pointer-events: none;
			width: 0;
			transition: unset;
		}

		&.is-visible {
			transition: unset;
		}
	}

	&__tablink {
		display: flex;
		justify-content: center;
		height: 100%;
		position: relative;
		text-decoration: none;
		width: calc(11vw + 5px);//216px;
		padding: 6vh 0;
		transition: width .3s ease;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-image: url('/images/main/bg-production-side.jpg');
			background-size: cover;
			opacity: 0;
			transition: opacity .3s ease;

			@include breakpoint(sm) {
				background-image: none;
			}
		}

		&--2 {
			&::before {
				background-image: url('/images/main/transport-side.jpg');
				@include breakpoint(sm) {
					background-image: none;
				}
			}
		}

		&--3 {
			&::before {
				background-image: url('/images/main/2_8-side.jpg');
				@include breakpoint(sm) {
					background-image: none;
				}
			}
		}

		&--4 {
			&::before {
				background-image: url('/images/main/slide4-side.jpg');
				@include breakpoint(sm) {
					background-image: none;
				}
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background: linear-gradient(90deg, #167EA2 -14.58%, rgba(31, 143, 181, 0) 122.08%);
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}
	}

	&__icon,
	&__link-text {
		position: relative;
		z-index: 3;
	}

	&__link-text {
		transform: rotate(-90deg) translateX(-4px) translateY(4vw);//rotate(-90deg) translateX(-13px) translateY(73px);
		// transform: rotate(-90deg);
		// font-size: 62px;
		// line-height: 74px;
		font-size: calc(3vw - 2px);//calc(6vw - 2px);
		line-height: 3vw;//7vw;
		color: $color-white;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		transform-origin: top left;
		min-width: 100vh;
		@include font(700, 'Akrobat');
		letter-spacing: 3.5px;
		text-transform: uppercase;

		@media (max-width: 1366px) {
			.browser-ie & {
				font-size: calc(3vw - 10px);
			}
		}
	}

	&__icon {
		display: block;
		width: calc(8vw - 13px);//140px;
	}

	&__icon-inner {
		height: calc(8vw - 13px);
		display: flex;
		align-items: flex-end;
	}

	&__info-block {
		width: calc(21vw - 10px);//394px;
		overflow: hidden;

		&--1 {
			margin: 6vh 0 0 8vw;
		}

		&--2,
		&--3,
		&--4 {
			margin: 6vh 8vw 0 0;
		}

		&--3,
		&--4 {
			width: calc(25vw - 10px);
		}

		&--4 {
			@media (max-width: 1366px) {
				.browser-ie & {
					margin-top: 1vh;
				}
			}
		}
	}

	&__icon-info {
		// width: 206px;
		width: 11vw;//23vh;
		margin: 0 auto 2vh;//22px;
	}

	&__white-block,
	&__link-block {
		background-color: $color-white;
	}

	&__white-block {
		padding: 3vh 0 0 0;
		margin-bottom: 2vh;
		position: relative;
		.mainpage__info-block--4 & {
			padding-top: 1vh;
			margin-bottom: 1vh;
		}
	}

	&__link-block {
		// position: relative;
		// display: inline-block;
		display: flex;
		flex-direction: column;

		// padding: 1vh 3vw 1vh 1vw;//13px 63px 13px 24px;
		padding: 1vh 1vw 1vh 1vw;//13px 63px 13px 24px;
		// @include webfont-icon($webfont-icon--i-main-arrow, 'after');

		// &::after {
		// 	color: $summer-sky;
		// 	font-size: 2vw;//36px;
		// 	position: absolute;
		// 	right: 1vw;//19px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }
	}

	&__white {
		// font-size: 20px;
		// line-height: 23px;
		font-size: calc(1vw + 1px);//calc(2vh + 1px);
		line-height: calc(1vw + 4px);
		color: $summer-sky;
		padding: 0 1vw;
		margin-bottom: 2vh;
		font-weight: 400;

		&--4 {
			font-size: calc(1vw - 1px);
			line-height: calc(1vw + 1px);
		}
	}

	&__uppercase {
		// font-size: 20px;
		// line-height: 24px;
		font-size: calc(1vw + 1px);//calc(2vh + 1px);
		line-height: calc(1vw + 4px);
		text-transform: uppercase;
		@include font(500, 'Akrobat');
		padding: 0 1vw;
		margin-bottom: 2vh;
		color: $summer-sky;
	}

	&__blue {
		background-color: $dark-cerulean;
		color: $color-white;
		@include font(500, 'Akrobat');
		// font-size: 18px;
		// line-height: 22px;
		font-size: calc(1vw - 4px);//calc(2vh + 1px);
		line-height: calc(1vw + 0px);
		padding: 2vh 1vw 2vh;
		margin-bottom: 0;

		.mainpage__info-block--4 & {
			padding: 1vh 1vw 1vh;
		}
	}

	&__link-info {
		// font-size: 16px;
		// line-height: 19px;
		font-size: calc(1vw - 3px);
		line-height: 1vw;
		@include font(300, 'Ubuntu');
		color: $summer-sky;

		margin-bottom: 1rem;
		@include font-size(14px);

		@media (max-width: 1366px) {
			.browser-ie & {
				margin-bottom: 5px;
			}
		}
	}

	&__link-part {
		// font-size: 16px;
		// line-height: 19px;
		font-size: calc(1vw - 3px);
		line-height: 1vw;
		@include font(500, 'Ubuntu');
		color: $summer-sky;
		text-decoration: underline;

		margin-bottom: .5rem;
		position: relative;
		@include font-size(12px);

		@include webfont-icon($webfont-icon--i-main-arrow, 'after');

		@media (max-width: 1366px) {
			.browser-ie & {
				margin-bottom: 5px;
			}
		}

		&:after {
			color: $summer-sky;
			// font-size: 2vw;//36px;
			font-size: 1.2em;
			position: absolute;
			right: 1vw;//19px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__number {
		// font-size: 180px;
		// line-height: 216px;
		font-size: 19vh;
		line-height: 22vh;
		@include font(800, 'Akrobat');
		color: $color-white;
		background-color: $mantis;
		width: 230px;
		text-align: center;
	}

	&__reshetka {
		line-height: 0;
		margin-bottom: -1px;
	}

	&__right {
		position: relative;
		&--1 {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&__right-text {
		background: rgba(38, 188, 237, 0.5);
		border: 5px solid $mantis;
		padding: 1vh 2vh 1vh 4vh;
		max-width: 36vw;
	}

	&__top-text,
	&__kz,
	&__main-text,
	&__text {
		color: $color-white;
		@include font(700, 'Akrobat');
	}

	&__top-text,
	&__kz {
		// font-size: 30px;
		// line-height: 36px;
		font-size: calc(4vh - 8px);
		line-height: calc(4vh - 2px);
		text-transform: uppercase;
	}

	&__main-text {
		// font-size: 75px;
		// line-height: 90px;
		font-size: calc(8vh - 1px);
		line-height: calc(10vh - 5px);
		margin: -1vh 0 -1vh 0;
	}

	&__kz {
		text-align: right;
	//     font-size: 30px;
	//     line-height: 36px;
	//     color: $color-white;
	//     @include font(700, 'Akrobat');

		.mainpage__tab-wrapper--2 & {
			margin-top: -5vh;

			[lang="en"] & {
				margin-top: -1vh;
			}
		}
	}

	&__text {
		// font-size: 21px;
		// line-height: 25px;
		font-size: calc(2vh + 1px);
		line-height: calc(2vh + 6px);
		@include font(500, 'Akrobat');
		background-color: $mantis;
		max-width: 230px;
		padding: 1vh 2vh 3vh 3vh;

		&--4 {
			max-width: 301px;
		}
	}

	&__close {
		position: absolute;
		right: 0;
		top: 10px;
		width: 43px;
		height: 30px;
		background-color: #003364;
		border-radius: 20px 0 0 20px;
		@include webfont-icon($webfont-icon--i-close, 'after');
		z-index: 10;
		transition: transform .3s ease;
		transform: translateX(100%);
		display: none;

		&::after {
			position: absolute;
			left: 10px;
			top: 6px;
			font-size: 18px;
			color: $summer-sky;

		}

		.is-active & {
			transform: translate(0);
		}
	}

	ul {
		margin: calc(1vw - 6px) 0 0;

	}

	ul > li {
		padding-left: 21px;
		font-size: calc(1vw - 4px);
    	line-height: 1vw;
    	@include font(500, 'Akrobat');
    	margin: calc(1vw - 6px) 0;

    	&:before {
			content: '';
			position: absolute;
			background-color: white;
			top: calc(0.4em * 1.2);
			border-radius: 100%;
			left: 0;
			@include rem(width, 6px);
			@include rem(height, 6px);
		}
    }

	@media (width: 1366px) {
		&__preloader {
			#video-preloader {
				object-position: top ;
				font-family: 'object-fit: cover; object-position: left top;';
			}
		}
	}

	@include breakpoint(md) {

		&__preloader {
			#video-preloader-tablet {
				display: block;
			}
			#video-preloader {
				display: none;
			}

			#video-preloader-mobile {
				display: none;
			}
		}

		height: calc(100vh - 50px);

		.browser-ie {
			height: calc(100vh - 45px);
		}

		&__main-text {
			font-size: calc(5.5vh - 1px);
			line-height: calc(7.5vh - 5px);
		}

		&__link-part {
			font-size: calc(1.4vw - 3px);
		}

		&__link-info {
			font-size: calc(1.4vw - 3px);
		}

		&__blue {
			font-size: calc(1.8vw - 4px);
			line-height: calc(1.8vw + 0px);
		}

		ul > li {
			font-size: calc(1.3vw - 4px);
			line-height: calc(1.3vw + 0px);
		}

		ul {
			margin: calc(1.3vw - 6px) 0 0;
		}
	}

	@media (orientation: portrait) {
		height: auto;
		overflow-x: hidden;

		&__preloader {
			#video-preloader-mobile {
				display: block;
			}

			#video-preloader {
				display: none;
			}

			#video-preloader-tablet {
				display: none;
			}
		}

		&__close {
			display: block;
		}

		&__wrapper {
			flex-direction: column;
			min-height: calc(100vh - 45px);
			justify-content: space-between;
		}

		&__content {
			width: 100% !important;
			height: 0;
			transition: all .3s ease;

			&.is-visible {
				height: auto;
			}
		}

		&__item {
			height: auto;
			min-height: 174px;
			flex-direction: column;
			position: relative;
			height: calc(100%/3);
			transition: all .3s ease;
			flex: 1 0 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;

			&--1 {
				background-image: url('/images/main/bg-production-mobile.jpg');
				[lang="en"] & {
					background-image: url('/images/main/bg-production-mobile-en.jpg');
				}
			}

			&--2 {
				background-image: url('/images/main/2_8-mobile.jpg');
			}

			&--3 {
				background-image: url('/images/main/transport-mobile.jpg');
			}

			&--4 {
				background-image: url('/images/main/slide4.jpg');
			}

			&.is-active {
				height: auto;
				flex: 1 0 auto;
				background-position: top;
			}
		}

		&__icon {
			display: none;
		}

		&__tab-wrapper {
			flex-direction: column;

			&--1 {
				flex-direction: column-reverse;
			}

			&--1,
			&--2,
			&--3,
			&--4 {
				background-image: none;
			}
		}

		&__link-text {
			transform: none;
			position: static;
			min-width: auto;
			width: auto;
			display: inline-block;
			font-size: 34px;
			line-height: 41px;
			letter-spacing: normal;
		}

		&__link-wrapper {
			width: 100%;
			background-color: transparent;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			&::before {
				background: none;
			}

			&.is-hidden {
				width: 100%;
			}
		}

		&__tablink {
			width: 100%;
			padding: 22px 0;

			&::after {
				background: linear-gradient(to bottom, #167EA2 24.73%, rgba(31, 143, 181, 0) 70.51%);
			}
		}

		&__reshetka {
			display: none;
		}

		&__right {
			margin-bottom: 29px;

			&--1 {
				align-items: flex-start;
			}
		}

		&__right-text {
			border-width: 2px;
			max-width: none;
			padding: 2px 10px 6px 14px;

			.mainpage__item--1 & {
				width: 190px;
			}

			.mainpage__item--2 &,
			.mainpage__item--4 & {
				width: 240px;
				padding-top: 11px;
			}

			.mainpage__item--3 & {
				width: 190px;
			}
		}

		&__number {
			font-size: 55px;
			line-height: 66px;
			width: 127px;
			height: 76px;
		}

		&__top-text {
			font-size: 12px;
			line-height: 14px;
		}

		&__main-text {
			font-size: 26px;
			line-height: 31px;
			margin: -6px 0 -1px;
		}

		&__kz {
			font-size: 12px;
			line-height: 14px;

			.mainpage__tab-wrapper--2 & {
				margin-top: -20px;
			}
		}

		&__text {
			width: 127px;
			font-size: 12px;
			line-height: 14px;
			padding: 8px 6px 15px 17px;
		}

		&__info-block {
			width: 100%;
			margin: 0;
		}

		&__white-block {
			margin-bottom: 0;
			padding: 23px 0 0;
		}

		&__icon-info {
			width: 118px;
			margin-bottom: 21px;
		}

		&__white {
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 16px;
			padding: 0 15px;
		}

		&__uppercase {
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 13px;
			padding: 0 15px;
		}

		&__blue {
			font-size: 15px;
			line-height: 18px;
			padding: 14px 15px 21px;
		}

		ul > li {
			font-size: 15px;
			line-height: 18px;
			margin: 15px 0;
		}

		ul {
			margin: 15px 0 0;
		}

		&__link-block {
			padding: 13px 15px 14px;
			width: 100%;

			&::after {
				display: none;
			}

		}

		&__link-info {
			font-size: 14px;
			line-height: 16px;
		}

		&__link-part {
			font-size: 14px;
			line-height: 16px;
			position: relative;
			@include webfont-icon($webfont-icon--i-main-arrow, 'after');

			&::after {
				color: $summer-sky;
				font-size: 30px;
				position: absolute;
				right: -40px;
				transform: translateY(-50%);
			}
		}
	}

	@include breakpoint(sm) {
		height: auto;
		overflow-x: hidden;

		&__preloader {
			#video-preloader-mobile {
				display: block;
			}

			#video-preloader {
				display: none;
			}
		}

		&__close {
			display: block;
		}

		&__wrapper {
			flex-direction: column;
			min-height: calc(100vh - 45px);
			justify-content: space-between;
		}

		&__content {
			width: 100% !important;
			height: 0;
			transition: all .3s ease;

			&.is-visible {
				height: auto;
			}
		}

		&__item {
			height: auto;
			min-height: 174px;
			flex-direction: column;
			position: relative;
			height: calc(100%/3);
			transition: all .3s ease;
			flex: 1 0 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;

			&--1 {
				background-image: url('/images/main/bg-production-mobile.jpg');
				[lang="en"] & {
					background-image: url('/images/main/bg-production-mobile-en.jpg');
				}
			}

			&--2 {
				background-image: url('/images/main/2_8-mobile.jpg');
			}

			&--3 {
				background-image: url('/images/main/transport-mobile.jpg');
			}

			&--4 {
				background-image: url('/images/main/slide4.jpg');
			}

			&.is-active {
				height: auto;
				flex: 1 0 auto;
				background-position: top;
			}
		}

		&__icon {
			display: none;
		}

		&__tab-wrapper {
			flex-direction: column;

			&--1 {
				flex-direction: column-reverse;
			}

			&--1,
			&--2,
			&--3,
			&--4 {
				background-image: none;
			}
		}

		&__link-text {
			transform: none;
			position: static;
			min-width: auto;
			width: auto;
			display: inline-block;
			font-size: 34px;
			line-height: 41px;
			letter-spacing: normal;
		}

		&__link-wrapper {
			width: 100%;
			background-color: transparent;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			&::before {
				background: none;
			}

			&.is-hidden {
				width: 100%;
			}
		}

		&__tablink {
			width: 100%;
			padding: 22px 0;

			&::after {
				background: linear-gradient(to bottom, #167EA2 24.73%, rgba(31, 143, 181, 0) 70.51%);
			}
		}

		&__reshetka {
			display: none;
		}

		&__right {
			margin-bottom: 29px;

			&--1 {
				align-items: flex-start;
			}
		}

		&__right-text {
			border-width: 2px;
			max-width: none;
			padding: 2px 10px 6px 14px;

			.mainpage__item--1 & {
				width: 190px;
			}

			.mainpage__item--2 &,
			.mainpage__item--4 & {
				width: 240px;
				padding-top: 11px;
			}

			.mainpage__item--3 & {
				width: 190px;
			}
		}

		&__number {
			font-size: 55px;
			line-height: 66px;
			width: 127px;
			height: 76px;
		}

		&__top-text {
			font-size: 12px;
			line-height: 14px;
		}

		&__main-text {
			font-size: 26px;
			line-height: 31px;
			margin: -6px 0 -1px;
		}

		&__kz {
			font-size: 12px;
			line-height: 14px;

			.mainpage__tab-wrapper--2 & {
				margin-top: -20px;
			}
		}

		&__text {
			width: 127px;
			font-size: 12px;
			line-height: 14px;
			padding: 8px 6px 15px 17px;

			&--4 {
				width: 187px;
			}
		}

		&__info-block {
			width: 100%;
			margin: 0;
		}

		&__white-block {
			margin-bottom: 0;
			padding: 23px 0 0;
		}

		&__icon-info {
			width: 118px;
			margin-bottom: 21px;
		}

		&__white {
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 16px;
			padding: 0 15px;
		}

		&__uppercase {
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 13px;
			padding: 0 15px;
		}

		&__blue {
			font-size: 15px;
			line-height: 18px;
			padding: 14px 15px 21px;
		}

		ul > li {
			font-size: 15px;
			line-height: 18px;
			padding: 0;
			padding-left: 21px;
			margin: 15px 0;
		}

		ul {
			margin: 15px 0 0;
		}

		&__link-block {
			padding: 13px 15px 14px;
			width: 100%;

			&::after {
				display: none;
			}

		}

		&__link-info {
			font-size: 14px;
			line-height: 16px;
		}

		&__link-part {
			font-size: 14px;
			line-height: 16px;
			position: relative;
			@include webfont-icon($webfont-icon--i-main-arrow, 'after');

			&::after {
				color: $summer-sky;
				font-size: 30px;
				position: absolute;
				right: -40px;
				transform: translateY(-50%);
			}
		}
	}
}

.browser-ie {
	.mainpage__link-part {
		text-decoration: none;
	}
}
