.button--excel {
	color: $tools--color;
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--xls);
	font-size: 0;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 20px;
	}

	&:hover {
		color: $tools--color-hover;
	}

	&:active {
		color: $tools--color-active;
	}
}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: 158px;
	width: 118px;
	opacity: 0;
	@include svg(118px, 158px, '/images/icons/download-arrow.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;
}

.download-arrow--ie8 {
	top: 10px;
	left: 20px;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0) progid:DXImageTransform.Microsoft.AlphaImageLoader(src="https://i.imgur.com/aMwoyfN.png", sizingMethod="scale") progid:DXImageTransform.Microsoft.matrix(M11=1, M12=1.2246063538223773e-16, M21=-1.2246063538223773e-16, M22=-1, SizingMethod="auto expand");
}

.download-arrow--ie9 {
	bottom: 50px;
	left: 67%;
}

// .download-arrow--chrome {
// 	bottom: 76px;
// 	left: 20px;

// 	.browser-yabrowser &,
// 	.browser-ie {
// 		bottom: auto;
// 		right: 20px;
// 		top:30px;
// 	}
// }

.download-arrow--safari {
	top: 4px;
	right: 81px;
	transform: rotateX(180deg) rotateY(180deg);
}

.download-arrow--firefox {
	top: 4px;
	right: 79px;
	transform: rotateX(180deg) rotateY(180deg);
}

.browser-chrome .download-arrow {
	bottom: 76px;
	left: 20px;
}

.browser-yabrowser .download-arrow {
	top: 0px;
	right: 16px;
	transform: rotate(-135deg);
}

.browser-ie .download-arrow {
	bottom: 85px;
	left: 50%;
	transform: translateX(-50%);
}

.browser-opera .download-arrow {
	top: 80px;
	right: 9%;
	transform: rotate(180deg);
}
