.b-tabs--horizontal {

	@include rem(border-width, 2px);
	@include rem(border-radius, 32px);
	border-style: solid;
	border-color: $pattens-blue;

	@include breakpoint(sm) {
		@include rem(border-radius, 22px);
	}

	.b-tabs {

		&__nav {
			background-color: $white-smoke;
			@include rem(border-width, 2px);
			@include rem(border-radius, 32px);
			border-style: solid;
			border-color: $pattens-blue;
			margin-top: -2px;
		    margin-left: -3px;
		    width: 100.7%;

		    @include breakpoint(sm) {
		    	width: auto;
		    	margin-top: 0;
		    	margin-left: 0;
		    	background-color: transparent;
		    	border: none;
		    }

		    &-item {
		    	border: 0;
		    }

		    &-text {
		    	color: $black-pearl;
		    	@include rem(letter-spacing, 1px);
		    }
		}
		
		&__button {

			background: $white-smoke;
			@include rem(border-radius, 32px);
			@include rem(padding, 8px 0 10px);
			@include font(500, 'Ubuntu');
			@include font-size(18px);
			text-align: center;
			@include webfont-icon($webfont-icon--i-up);
			position: relative;

			@include breakpoint(sm) {
				background-color: transparent;
			}

			&:before {
				color: transparent;
				position: absolute;
				transform: rotate(180deg);
				font-size: 10px;
				bottom: -14px;
				left: 50%;
				margin-left: -15px;

				@include breakpoint(sm) {
					display: none;
				}
			}

			&:hover, &:hover:visited {
				background: $summer-sky;
				box-shadow: 0 0 1px 2px $summer-sky;
				z-index: 20;

				@include breakpoint(sm) {
					box-shadow: none;
				}

				span {
					color: $white;
				}
			}
			

			&.is-expand, &.is-expand:visited {
				background: $my-sin;
				box-shadow: 0 0 1px 2px $my-sin;
				z-index: 10;

				@include breakpoint(sm) {
					box-shadow: none;
				}
				
				&:before {
					color: $my-sin;
				}

				.b-tabs__nav-text {
					color: $white;
				}
			}
		}
		
		&__body {
			background: transparent;
		}

		&__article {
			border: none;
			@include rem(padding, 40px 46px 23px);

			h4 {
				@include font(300, 'Ubuntu');
				color: $eclipse;
				@include font-size(16px);
				@include rem(line-height, 22px);
			}
		}

		&__nav-text {
			margin: auto;
			display: inline-block;
			width: 100%;
		}
	}
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-tabs--horizontal {
			.col--no-gutter {
				padding-left: 0;
				padding-right: 0;
			}

			.b-tabs__nav-item {
				border-left: none;
				border-top: 1px solid $color-white;

				&:first-child {
					border-top: none;
				}
			}
		}
	}
}
