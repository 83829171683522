$menu--panel-bg: #E1E8EE;

$menu--arrow-color: #1C2F3B;
$menu--arrow-color-hover: $my-sin;
$menu--arrow-color-active: $my-sin;

$menu--burger-color: $base-text-color;
$menu--burger-color-hover: $color-black;
$menu--burger-color-active: $color-carrot-orange;

.b-nav--top.b-nav--horizontal-wide-page {
	.b-nav__burger {
		@include webfont-icon($webfont-icon--i-menu);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include rem(width, 34px);
		@include rem(height, 27px);
		color: $color-white;
		transition: all .3s ease;
		text-decoration: none;

		&:hover {
			color: $my-sin;
			text-decoration: none;
		}

		&:active {
			color: $summer-sky;
		}

		&::before {
			@include font-size(25px);
		}

		&.is-expand {
			@include webfont-icon($webfont-icon--i-close);
		}
	}

	.b-nav__burger-text {
		display: none;
		font-size: 13px;
		line-height: 16px;
		text-transform: uppercase;
		padding-top: 36px;

		&--close {
			display: none;
		}
	}

	.b-nav__panel {
		position: fixed;
		display: none;
		top: 67px;
		left: 0;
		width: 100%;
		height: calc(100% - 67px);
		z-index: 11;
		background-color: $menu--panel-bg;
	}

	.b-nav__panel-inner {
		width: 100%;
		height: 100%;

		// &:before {
		// 	content: '';
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 120px;
		// 	top: 0;
		// 	left: 0;
		// 	background: linear-gradient(to bottom, $menu--panel-bg 80%, transparent);
		// 	z-index: 2;
		// }
	}

	.l-nav-cols {
		padding: 89px 20px 1vh;
		max-width: 1298px;
		margin: auto;
	}

	.os-scrollbar {
		z-index: 3;

		&.os-scrollbar-vertical {
			width: 11px !important;
			right: 8px !important;
			height: calc(100% - 30px) !important;
			top: 50%;
			transform: translateY(-50%);
		}

		.os-scrollbar-handle {
			background-color: #A4AEB6 !important;
		}

		.os-scrollbar-track {
			&::before {
				content: '';
				background-color: #CFD8DF;
				width: 1px;
				height: 100%;
				position: absolute;
				left: 3px;
				top: 0;
			}
		}
	}

	.b-nav__list--lvl1 {
		width: 100%;
		height: 100%;
	}

	.b-nav__list--lvl2 {
		display: block;
		position: relative;

		.b-nav__list {
			display: none;

			&.is-expand {
				display: block;
			}
		}
	}

	.b-nav__list--lvl3 {
		margin: 16px 0 0 24px;
	}

	.b-nav__item {
		display: block;

		&--lvl2 {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.b-nav__item--lvl1 {
		margin-bottom: 3em;
	}

	.b-nav__link-wrapper {
		position: relative;
		display: flex;
	}

	.b-nav__link {
		position: relative;
		display: block;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		line-height: 1.2;
		transition: color 0.2s ease, background-color 0.2s ease;
		text-decoration: none;

		&.is-expand,
		&:hover {
			color: $menu--link-color-expand-hover;
		}

		&:active {
			color: $menu--link-color-active;
		}

		&.current {
			color: $menu--link-color-current;
		}

		&.is-expand + .b-nav__arrow {
			color: $menu--link-color-expand-hover;
			&::before {
				transform: rotate(-90deg);
			}
		}
	}

	.b-nav__arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1em;
		height: 1em;
		cursor: pointer;
		color: $menu--arrow-color;
		transition: color 0.2s ease;
		user-select: none;
		@include webfont-icon($webfont-icon--i-arrow-breadcrumbs);
		margin-left: 8px;
		margin-top: 2px;

		&::before {
			font-size: 13px;
			transform: rotate(90deg);
		}

		&:hover {
			color: $menu--arrow-color-hover;
		}

		&:active {
			color: $menu--arrow-color-active;
		}
	}

	.b-nav__link--lvl1 {
		@include rem(margin-bottom, 31px);
		text-transform: uppercase;
		color: $dark-cerulean;
		font-size: 20px;
		line-height: 24px;
		@include font(700, 'Akrobat');
	}

	.b-nav__link--lvl2 {
		font-size: 16px;
		line-height: 18px;
	}

	.b-nav__link--lvl3 {
		font-size: 14px;
		line-height: 28px;
		@include font(300, 'Akrobat');
	}

	.b-nav__link--lvl4 {
		font-size: 14px;
		line-height: 26px;
		@include font(300, 'Akrobat');
	}

	@if ($responsive) {

		@include breakpoint(md) {
			
			.b-nav__burger {
				@include rem(width, 26px);
				@include rem(height, 19px);

				&:before {
					@include font-size(19px);
				}
			}

			.b-nav__panel {
				top: 50px;
				height: calc(100% - 50px);
			}
		}
	}
}
