.b-indicator {
	display: block;
	@include rem(border-left-width, 14px);
	border-style: solid;
	border-color: $solitude;
	@include rem(padding, 10px 20px 13px);
	@include rem(margin-bottom, 25px);

	&__main {
		margin-bottom: 10px;
	}

	&__value {
		@include font(800, 'Akrobat');
		@include rem(font-size, 64px);
		@include rem(line-height, 52px);
		@include rem(margin-bottom, 9px);
		color: $dark-cerulean;
	}

	&__value--big {
		@include font-size(45px);
	}

	&__value--small {
		@include font-size(39px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		@include font(800, 'Akrobat');
		@include rem(font-size, 64px);
		@include rem(line-height, 52px);
		@include rem(margin-bottom, 9px);
		color: $dark-cerulean;

		&--block {
			display: block;
		}
	}

	&__unit--big {
		@include font-size(30px);
	}

	&__unit--small {
		@include font-size(24px);
		font-weight: 400;
	}

	&__text {
		display: block;
		@include font-size(15px);
		font-weight: 400;
		line-height: 1.2;
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__heading {
		@include font(800, 'Akrobat');
		@include rem(font-size, 64px);
		@include rem(line-height, 52px);
		@include rem(margin-bottom, 9px);
		color: $dark-cerulean;

		.units {
			@include rem(font-size, 30px);
		    @include rem(margin-left, -4px);
			@include rem(letter-spacing, 0.5px);
		}

		.units-last-block {
			display: block;
    		margin-top: 10px;
		}

		@include breakpoint(md) {
			.units-first-block {
				display: block;
				margin-top: 10px;
			}
		}

		@include breakpoint(sm) {
			.units-first-block {
				display: inline;
			}

			.units-last-block {
				display: inline;
			}
		}
	}

	&__text {
		@include font(500, 'Ubuntu');
		font-weight: 500;
		@include rem(font-size, 16px);
		@include rem(line-height, 18px);
		color: $summer-sky;
		@include rem(max-width, 250px);
	}
}