$tabs--button-bg: $color-dark-gray;
$tabs--button-bg-active: $color-ironside-gray;
$tabs--button-bg-hover: $color-taupe-gray;
$tabs--button-text-color: $color-white;
$tabs--button-text-color-hover: $color-white;
$tabs--body-bg: $color-white;

.b-tabs {

	@include rem(margin-bottom, 50px);

	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		@include rem(padding, 5px);
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font-size(18px);

		transition: all .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-hover;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-hover;
			}
		}

		&.is-expand {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-active;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;

			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		@include rem(padding, 15px 15px 16px 15px);
		border-left: 1px solid $color-celeste;
		border-right: 1px solid $color-celeste;
		border-bottom: 1px solid $color-celeste;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}
