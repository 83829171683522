body.is-locked {
	overflow: hidden;
}

.push-right,
%push-right {
	float: right;
}

.push-left,
%push-left {
	float: left;
}

.clearfix,
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.webfont-button-animation,
%webfont-button-animation {
	transition: color 0.2s ease, transform 0.2s ease;

	&:active {
		transform: translateY(1px);
	}
}

.bold {
	font-weight: bold;
}

.light {
	font-weight: normal;
}

.underline {
	text-decoration: underline;
}

.underline--no-on-hover {
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.underline--on-hover {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.underline--on-hover-none {
	@extend .underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.underline--no {
	text-decoration: none !important;

	&:hover,
	&:focus {
		text-decoration: none !important;
	}
}

%disable-visited {
	&:visited {
		color: inherit;
	}
}

.uppercase {
	text-transform: uppercase;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

@include margin(top);

@include margin(right);

@include margin(left);

@include margin(bottom);

@include padding(top);

@include padding(right);

@include padding(left);

@include padding(bottom);

.all-padding {
	padding: $grid-gutter-width;
}

.all-padding--small {
	padding: $grid-gutter-width / 2;
}

.all-padding--no {
	padding: 0 !important;
}

.inline,
%inline-block {
	display: inline-block;
}

.bottom-line {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.bottom-line--thick {
	@extend .bottom-line;
	border-bottom-width: 3px;
}

.bottom-line--dotted {
	@extend .bottom-line;
	border-bottom-style: dotted;
}

.bottom-line--dashed {
	@extend .bottom-line;
	border-bottom-style: dashed;
}

.bottom-border--no {
	border-bottom: none;
}

.solid-border {
	border: 1px solid;
}

.vertical-centering {
	display: table;
}

.vertical-centering__el {
	display: table-cell;
	vertical-align: middle;
}

.static,
%static {
	position: static;
}

.relative,
%relative {
	position: relative;
}

.fixed,
%fixed {
	position: fixed;
}

.sticky,
%absolute {
	position: absolute;
}

.sticky--bottom {
	bottom: 0;
}

.sticky--right {
	right: 0;
}

.sticky--left {
	left: 0;
}

.sticky--top {
	top: 0;
}

.center-position,
%center-position {
	@extend %absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.collapse--top,
%collapse--top {
	margin-top: 0;
}

.bottom-layer {
	z-index: -1;
}

.left-gutter {
	padding-left: $grid-gutter-width;
}

.block,
%d-block {
	display: block;
}

.hidden,
%d-none {
	display: none;
}

.centered-block {
	margin-left: auto;
	margin-right: auto;
	float: none;
}

.overflow-hidden,
%overflow-hidden {
	overflow: hidden;
}

.entire-height,
%entire-height {
	height: 100%;
}

.line-height--no {
	line-height: 1;
}

.user-select--no {
	user-select: none;
}

.j-space-between-fit {
	@media (min-width: 1780px) {
		justify-content: space-between;
	}

	@media (min-width: $breakpoint-sm + 1px) {
		& > .col {
			flex-grow: 0;
			flex-basis: auto;
		}
	}
}

@include breakpoint(xl) {
	.hide-xl {
		display: none;
	}

	.show-xl {
		display: block;
	}
}

@include breakpoint(lg) {
	.hide-lg {
		display: none;
	}

	.show-lg {
		display: block;
	}
}

@include breakpoint(md) {
	.hide-md {
		display: none;
	}

	.show-md {
		display: block;
	}
}

@include breakpoint(sm) {
	.hide-sm {
		display: none;
	}

	.show-sm {
		display: block;
	}
}

@include breakpoint(xs) {
	.hide-xs {
		display: none;
	}

	.show-xs {
		display: block;
	}
}
