// Цвет фона фиксированной шапки
$table--fixed-head-bg: $color-white;
// Шапка
$table--header-color: $pacific-blue;
$table--header-bg: $white;
// Подзаголовок
$table--subhead-color: $color-navy-blue;
// Выделенная ячейка
$table--strong-cell-color: $base-text-color;
$table--strong-cell-bg: $white-smoke;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-bg: $white;
$table--summary-color: $pacific-blue;
$table--summary-hover: rgba($summer-sky, 0.2);
$table--summary-active-js: $table--summary-hover;
// Фон строки по наведению
$table-row-bg-hover: $summer-sky;
$table-row-bg-strong-hover: $summer-sky;
// Выделенная строка
$table--strong-row-color: $table--strong-cell-color;
$table--strong-row-bg: $table--strong-cell-bg;
$table--strong-row-bg-hover: $color-mountain-mist;
$table--strong-row-bg-js: $table--strong-row-bg-hover;
// Выделенная строка (при клике)
$table--selected-row-bg: $summer-sky;
$table--selected-row-text: $white;
$table--selected-row-bg-strong: $summer-sky;

//Шапка таблицы activity
$table--header-color-acti: $dark-cerulean;
$table--header-bg-acti: $alice-blue;
//Первая ячейка tbody таблицы activity
$table--tbody-color-acti-first: $dark-cerulean;

@if ($contrast) {
	$table--header-color: $color-white;
	$table--header-bg: $color-mine-shaft;
	$table--strong-cell-color: $color-taupe-gray;
	$table--strong-cell-bg: $color-eallery;
	$table--strong-cell-border: $color-davys-grey;
	$table--subhead-color: $color-error;
	$table--summary-bg: $color-dark-jungle;
	$table--summary-color: $color-white;
	$table--fixed-head-bg: $color-tapa-gray;
}

table {
	border-collapse: collapse;
	border-spacing: 0;


	td,
	th {
		padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {

	.figure-buttons {
		@include rem(margin-top, 13px);

		.button--zoom {
			order: -1;
			margin-left: 2px;
			margin-right: 11px;
		}
	}

	@include rem(margin-bottom, 49px);
	position: relative;

	figcaption {
		@include font-size(20px);
		@include rem(line-height, 24px);
		@include font(700, 'Akrobat');
		@include rem(margin-bottom, 10px);
		@include rem(max-width, 500px);
		text-transform: uppercase;
		color: $eclipse;


		.caption__units,
		.b-caption__units {
			font-weight: normal;
		}
	}

	table {
		min-width: 100%;

		thead {
			//Ячейки в заголовках
			td,
			th {
				color: $table--header-color;
				background-color: $table--header-bg;
				text-align: right;
				@include rem(padding, 8px 17px 7px);
				@include font-size(17px);
				@include rem(line-height, 24px);
				@include font(400, 'Akrobat');
				// @include rem(border-bottom-width, 2px);
				border-bottom: 2px solid $dark-cerulean;
				// border-bottom-style: solid;
				// border-bottom-color: $dark-cerulean;

				&::after {
					display: none;
				}

				// .browser-ie & {
				// 	border-bottom: 0;
				// 	&::after {
				// 		content: '';
				// 		position: absolute;
				// 		left: 0;
				// 		bottom: -2px;
				// 		height: 2px;
				// 		width: 100%;
				// 		background-color: $dark-cerulean;
				// 		z-index: 1;
				// 	}
				// }

				&[colspan] {
					text-align: center;
				}
			}

			tr:first-child {
				th:first-child,
				td:first-child {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				&:hover,
				&.is-hover {
					background-color: rgba($table-row-bg-hover, 0.2);

					.b-cell--strong {
						background-color: rgba($table-row-bg-strong-hover, 0.2);
					}
				}
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
			@include rem(padding, 12px 13px);
			@include rem(line-height, 16px);
			@include font-size(16px);
			@include font-face(400, 'Ubuntu');
			@include rem(border-bottom-width, 1px);
			border-bottom-style: solid;
			border-bottom-color: rgba($bright-grey, 0.3);
			border-bottom: 1px solid rgba($bright-grey, 0.3);

			/*&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -1px;
				height: 1px;
				width: 100%;
				background-color: rgba($bright-grey, 0.3);
				z-index: 1;
			}*/

			&.b-thead {
				color: $dark-cerulean;
				background: $table--header-bg-acti;
				@include rem(padding, 1px 9px 6px);
				@include font-size(17px);
				@include rem(line-height, 17px);
				@include font(400, 'Akrobat');
				border-bottom: 2px solid $dark-cerulean;
				vertical-align: top;
				text-align: left;
			}

			&:first-child {
				text-align: left;
			}
		}
	}

	//Подзаголовок
	.b-subhead {
		font-weight: bold;
		text-transform: uppercase;
		color: $table--subhead-color;
	}

	.b-subhead--level-1 td:first-child {
		padding-left: 0;
	}

	.b-subhead--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-subhead--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	//Выделеная ячейка
	.b-cell--strong {
		background-color: #E8F1FA;
		z-index: -1;
		// .browser-ie & {
		// 	position: relative;
		// 	background-color: transparent;
		// 	&::before {
		// 		content: '';
		// 		position: absolute;
		// 		bottom: 0;
		// 		height: 100%;
		// 		width: 100%;
		// 		background-color: #E8F1FA;
		// 		left: 0;
		// 	}
		// }
	}

	// .b-cell--strong + .b-cell--strong {
	// 	&:before {
	// 		@include pseudo(1px, 100%, '');
	// 		background-color: $table--strong-cell-border;
	// 		top: 0;
	// 		left: 0;
	// 	}
	// }

	.b-cell--level-1 {
		padding-left: 0;
	}

	.b-cell--level-2 {
		@include rem(padding-left, 25px);
	}

	.b-cell--level-3 {
		@include rem(padding-left, 50px);
	}

	.b-cell--regular {
		font-weight: 400 !important;
	}

	.b-cell--bold {
		font-weight: 700 !important;
	}

	//Выделеная строка
	.b-row--strong {
		td {
			font-weight: 700;
			&:first-child {
				font-weight: 700;
			}
		}
	}

	//Выбранная строка(по клику)
	.b-selected-row {
		td {
			background-color: $table--selected-row-bg !important;
			color: $table--selected-row-text !important;
		}

		.b-cell--strong {
			background-color: $table--selected-row-bg-strong !important;
		}
	}

	//Итоговая строка
	.b-summary {

		td {
			@include rem(padding-top, 16px);
			@include rem(padding-bottom, 13px);
			@include font-face(500, 'Ubuntu');
			text-transform: uppercase;
			font-weight: 500;
			@include rem(line-height, 18px);
			color: $table--summary-color;

			.b-cell--strong {
				background-color: $table--selected-row-bg-strong !important;
			}
		}



		&:hover {
			tr, td, th {
				background-color: $table--summary-hover;
			}
		}

		.is-active,
		.is-hover {
			background-color: $table--summary-active-js;
		}
	}

	.b-summary--level-1 td:first-child {
		padding-left: 0;
	}

	.b-summary--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-summary--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 10;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 0;
		background-color: $table--fixed-head-bg;
	}

	// Цвет всего столбца, по наведению на ячейку
	.is-hover {
		background-color: $color-eallery;
	}

	// Цвет всего столбца, по клику на заголовок столбца
	.is-active {
		background-color: $color-eallery;
	}
}

.b-table--simple {
	table {
		thead {
			th,
			td {
				text-align: left;
			}
		}

		td,
		th {
			text-align: left;
		}
	}
}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	background: $white;
	display: inline-block;
	color: transparent;
	@include rem(padding, 3px 10px);
	@include webfont-icon($webfont-icon--vector);
	text-decoration: none;
	position: absolute;
    right: 0px;
    bottom: -6px;

    &:before {
    	position: absolute;
	    right: 2px;
	    bottom: 9px;
	}

	&:after {
		content: "Развернуть";
		position: absolute;
	    right: 18px;
   		bottom: 3px;
		color: $summer-sky;
	}

	&:hover::after {
		color: $my-sin;
	}

	&:active::after {
		color: $dark-cerulean;
	}

	&:before {
		color: $summer-sky;
		font-size: 10px;
	}

	&:hover::before {
		color: $my-sin;
	}

	&:active::before {
		color: $dark-cerulean;
	}
}

.b-table__toggle-visible-rows-trigger.is-expand {

	right: -40px;

	&:after {
		content: "Свернуть";
		right: 57px;
	}

	 &:before {
		transform: rotate(180deg);
		bottom: 9px;
    	right: 42px;
	}
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
		max-width: 70vw;
	}
}

.b-table__content,
.b-table {
	::-webkit-scrollbar {
		height: 14px;
	}

	::-webkit-scrollbar-track {
		background: $color-white;
	}

	::-webkit-scrollbar-thumb {
		background: #919CAB;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		@include bg-rgba(145, 156, 171, 0.7);
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: rgba(255, 255, 255, 0.01);
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	@extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		&:not(.disabled):hover {
			opacity: 0.7;
		}

		&.disabled {
			opacity: 0.4;
		}

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: $color-white;
	}

	.b-scroll-arrow--left {
		background: linear-gradient(90deg, rgba(97, 110, 121, 1), rgba(255, 255, 255, 0));
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(253, 201, 10, 1), rgba(255, 255, 255, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(97, 110, 121, 1));
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(253, 201, 10, 1));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			table {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				@include font-size(20px);
			}
		}

		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}

.b-table.b-table--activity {
	figcaption {
		@include rem(margin-bottom, 22px);
	}

	table {
		thead {
			td, th {
				color: $table--header-color-acti;
				background: $table--header-bg-acti;
				@include rem(padding, 1px 9px 6px);
				@include font-size(17px);
				@include rem(line-height, 17px);
			}
		}

		tbody {
			td, th {

				&:first-child {
					color: $table--tbody-color-acti-first;
					@include font(400, 'Akrobat');
				}
			}
		}
	}
}
