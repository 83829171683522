.b-form {
	display: block;
	width: 100%;

	input::-ms-clear {
		display: none;
	}

	&__title {
		margin-bottom: .8em;
		margin-top: 0;
		padding: 0;
	}

	&__fields {}

	&__field {}

	&__field:last-child {
		margin-bottom: 0;
	}

	&__field-title {
		margin-bottom: 0.4em;
	}

	&__field-placeholder--smart,
	&__field-placeholder--smart-top {
		cursor: text;
		position: absolute;
		padding: $input-padding;
		transition: all 0.2s ease;
		background-color: transparent;
		white-space: nowrap;
		text-overflow: ellipsis;
		left: 0;
		color: $base-text-color;

		span {
			color: $color-error;
		}
	}

	&__field-placeholder--smart {
		top: 0.6em;
	}

	&__field-placeholder--smart-top {
		top: 0.6em;
	}

	&__field-input {
		position: relative;
		display: inline-block;
		width: 100%;
		@include rem(margin-bottom, 20px);
		@include rem(padding-bottom, 12px);

		.b-captcha & {
			@include rem(margin-bottom, 2px);
		}

		//Текст ошибки для невалидного поля
		.parsley-errors-list {
			& > li {
				position: absolute;
				display: block;
				color: $error-text-color;
				@include font-size(12px);
			}
		}
	}

	&__field-input input,
	&__field-input textarea {
		width: 100%;
		transform: translateZ(0);
	}

	&__field-input textarea {
		display: block;
		resize: none;
	}

	&__field-input--smart-placeholder {
		//Меняем положение span с текстовой меткой при состоянии focus или not-empty родительского label
		&.is-focused .b-form__field-placeholder--smart,
		&.is-focused .b-form__field-placeholder--smart-top {
			opacity: 1;
			// top: 9px;
			top: 14px;
			transform: translateY(-150%);
			color: $color-black;
			background-color: $color-white;
			cursor: default;
			@include rem(padding, 2px);
			@include rem(line-height, 14px);
			@include font-size(14px);

			// &:after {
			// 	content: '';
			// 	display: block;
			// 	width: 12px;
			// 	height: 12px;
			// 	border-style: solid;
			// 	border-width: 6px;
			// 	border-color: $color-ironside-gray transparent transparent transparent;
			// 	position: absolute;
			// 	bottom: -11px;
			// 	left: 10px;
			// 	cursor: default;
			// }
		}

		&.is-fill .b-form__field-placeholder--smart,
		&.is-fill .b-form__field-placeholder--smart-top {
			opacity: 0;
		}

		// additional
		&.b-form__field-input--textarea {

			textarea {
				padding-top: 6px;
			}

			&.is-focused .b-form__field-placeholder--smart,
			&.is-focused .b-form__field-placeholder--smart-top {
				top: 18px;
				left: 6px;
				@include rem(padding, 2px 6px);
				@include rem(line-height, 16px);
				@include font-size(16px);
			}

			&.is-fill .b-form__field-placeholder--smart,
			&.is-fill .b-form__field-placeholder--smart-top {
				opacity: 0;
			}
		}
	}

	&__field-input--policy {
		display: flex;
		color: #bcbdbf;
		font-size: 14px;
		margin-bottom: 1.75em;
		padding: 0;

		.b-checkbox {
			margin-top: 0.05em;
			margin-right: 9px;
			padding-top: 0.2em;
		}

		.b-form__field-text {
			@include font-size(14px);
			@include rem(line-height, 16px);
		}

		.parsley-errors-list li {
			top: 100%;
			margin-left: 0;
		}

		a:visited {
			color: $link-default-color;
			background-color: transparent;

			&:hover {
				background-color: $color-black;
				color: $link-hover-color;
				text-decoration: none;
			}
		}
	}

	&__field-input--send {
		@include breakpoint(xs) {
			@include rem(margin-bottom, 14px);

			.btn {
				width: 100%;
			}
		}
	}

	&__footnote {
		span {
			color: $color-error;
		}
	}

	.b-captcha {
		@include rem(padding-bottom, 10px);
	}
}
