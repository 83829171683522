$copyright_base--color: $color-white;
$copyright_base--link-color: $color-white;
$copyright_base--link-color-hover: $color-white;
$copyright_base--link-color-active: $color-white;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;
	@include font-size(14px);
	@include rem(line-height, 16px);
	@include font(300, 'Ubuntu');

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover {
			color: $copyright_base--link-color-hover !important;
			text-decoration: none;
		}

		&:active {
			color: $copyright_base--link-color-active !important;
		}
	}
}
