$preloader--size-width: 122px;
$preloader--size-height: 122px;
$preloader--bg-color: $color-white;
$preloader--bg-opacity: 0.9;
$preloader--color: $color-gray;

.preloader {
	min-height: $preloader--size-height * 2;
	min-width: $preloader--size-width * 2;
	z-index: 999999999;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		@include svg($preloader--size-height, $preloader--size-height, '/images/components/preloader/.variant/base/preloader.svg', null, $preloader--color);
		background-position: center center;
		background-color: rgba($preloader--bg-color, $preloader--bg-opacity);
		z-index: 1;
	}
}

.preloader--is-button {
	min-height: inherit;
	min-width: inherit;

	&:after {
		@include svg(auto, 80%, '/images/components/preloader/.variant/base/preloader.svg', null, $preloader--color);
	}
}

.preloader:not(.preloader--is-flow-item) {
	position: relative;
}

.feature-no-svg .preloader:after {
	background-image: url('/images/components/preloader/.variant/base/preloader.gif') !important;
}
