$button-size: 1.45em;
$analysis-bg-color: rgba($btn--bg, 0.15);
$analysis-bg-color-hover: rgba($project--main-color, 0.4);
$analysis-bg-color-active: rgba($project--main-color, 0.9);
$analysis-color: $color-text-1;
$analysis-color-hover: $color-white;
$analysis-color-active: $color-white;

@if ($contrast) {
	$analysis-bg-color: rgba($color-1, 0.15);
	$analysis-bg-color-hover: rgba($color-1, 0.3);
	$analysis-bg-color-active: rgba($color-1, 0.5);
	$analysis-color: $color-text-1;
	$analysis-color-hover: $color-text-1;
	$analysis-color-active: $color-text-1;

}

.analysis__preset {
	@extend %button;
	@include rem(padding, 8px 10px);
	@include rem(margin, 0px 1px 5px 1px);

	&:not(.is-selected) {
		color: $btn--color-disabled;
		background-color: $btn--bg-disabled;
		border-color: $btn--border-color-disabled;

		&:hover {
			background-color: $btn--bg-hover;
			color: $btn--color-hover;
			border-color: $btn--border-color-hover;
		}
	}
}

.analysis__main {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.analysis__chart-section {
	display: table-cell;
	vertical-align: top;
	padding-left: 10px;
}

.analysis__chart-section,
.analysis__items-list {
	float: none !important;
}

.analysis__items-list {
	width: 320px;
	display: table-cell;
	vertical-align: top;

	.b-accordion__button {
		font-size: 1em;
	}

	.items-group__item {
		@include rem(padding, 5px 5px 5px 20px);
		@include font-size(12px);
		text-indent: 0;

		&.is-even:not(.is-selected):not(:hover) {
			background-color: $analysis-bg-color;
			color: $analysis-color;
		}

		&:hover {
			background-color: $analysis-bg-color-hover;
			color: $analysis-color-hover;
		}

		&.is-selected {
			background-color: $analysis-bg-color-active;
			color: $analysis-color-active;
		}

		&.subgroup {
			@include rem(padding-left, 30px);
		}
	}

	.b-accordion__article {
		background: transparent;
	}

	.b-accordion-functional__article {
		@include rem(padding, 14px 0);
	}
}

.analysis__table {
	@include font-size(13px);

	th {
		border-bottom: 2px solid $project--main-color;
		@include rem(padding, 5px);
	}

	td {
		@include rem(padding, 5px);
	}
}

.analysis__years-list {
	@include rem(margin-top, 10px);

	.years-list__year {
		@extend %button;
		@include rem(padding, 5px);

		&:nth-of-type(1) {
			@include rem(margin-left, 6px);
		}

		& + .years-list__year {
			@include rem(margin-left, 5px);
		}

		&:not(.is-selected) {
			color: $btn--color-disabled;
			background-color: $btn--bg-disabled;
			border-color: $btn--border-color-disabled;

			&:hover {
				background-color: $btn--bg-hover;
				color: $btn--color-hover;
				border-color: $btn--border-color-hover;
			}
		}
	}
}

.analysis__chart-area {
	margin-top: 50px;
}

.analysis__buttons {
	white-space: nowrap;
	display: inline-block;
	margin: 0;
	margin-top: -35px;

	.analysis__button {
		background-color: transparent;
		display: inline-block;
		color: $tools--color;
		@extend %webfont-button-animation;

		&:before {
			font-size: $button-size;
		}

		&:hover {
			background-color: transparent;
			color: $tools--color-hover;
		}

		&:active {
			background-color: transparent;
			color: $tools--color-active;
		}
	}

	.analysis__button + .analysis__button {
		@include rem(margin-left, 12px);
	}

	.analysis__button--line {
		@include webfont-icon($webfont-icon--line);
	}

	.analysis__button--histogram {
		@include webfont-icon($webfont-icon--histogram);
	}

	.analysis__button--excel {
		@include webfont-icon($webfont-icon--file-excel);
	}
}

.analysis__items-list .b-accordion__article {
	padding: 0;
}

.c3-tooltip td.name {
	max-width: 700px;
}

@include breakpoint(md) {
	.analysis__main {
		display: block;
	}

	.analysis__items-list {
		display: block;
		width: 100%;
	}

	.analysis__chart-section {
		display: block;
		width: 100%;
		padding: 0;
	}
}

@include breakpoint(xs) {
	.analysis__table {
		@include rem(font-size, 8px)
	}

	.analysis__years-list {
		@include rem(font-size, 12px);
	}

	.c3-tooltip-container {
		left: 50% !important;
		transform: translateX(-50%);
		width: 320px;
	}
}
