.b-useful-links--static {
	.b-useful-links {
		&__title {
			@include rem(padding, 0 23px 12px);
			text-transform: uppercase;
			color: $summer-sky;
			@include font(700, 'Ubuntu');
    		@include rem(letter-spacing, 0.6px);
			@include font-size(14px);
			@include rem(margin-bottom, 12px);
			border-bottom: 2px solid $pattens-blue;
			margin-top: 0;
		}

		&__list {
			@include rem(padding-top, 10px);
		}
	}
}
