.content-area,
#colorbox,
.tooltipster-base {

	& > h6 {
		@include font-size(16px);
		@include font(600, 'Ubuntu');
		font-weight: 600;
		@include rem(margin-bottom, 10px);
		@include rem(line-height, 26px);
		color: $black-pearl;
	}

	li {
		position: relative;
		text-align: left;

		&:first-child {
			margin-top: 0;
		}

		& + li {
			margin: 0.5em 0;
		}
	}

	ul {
		@include rem(margin-bottom, 26px);

		ol {
			li {
				padding-left: 1.5em;
			}
		}

		ul {
			margin-top: 10px;
		}

		& > li {
			@include rem(padding-left, 21px);
			@include font(300, 'Ubuntu');

			&:before {
				content: '';
				position: absolute;
				background-color: $dark-cerulean;
				top: calc(0.4em * 1.2);
				border-radius: 100%;
				left: 0;
				@include rem(width, 8px);
				@include rem(height, 8px);
			}

			& ul > li {

				&:before {
					background-color: $danube;
				}

				& ul > li {

				&:before {
						background-color: $periwinkle;
					}
				}
			}
		}
	}

	ul.current-color {
		& > li:before {
			background-color: currentColor;
			border-color: currentColor;
		}
	}

	ol.current-color {
		& > li:before {
			color: currentColor;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 1.5em;

			&:before {
				display: none;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;
		list-style-type: none;
		@include rem(margin-bottom, 26px);

		& > li {
			@include rem(padding-left, 26px);
			@include font(300, 'Ubuntu');

			&:before {
				content: counters(list-numbers, ".") ". ";
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				top: 0;
				left: 0;
				color: $dark-cerulean;
				@include font(600, 'Ubuntu');
				font-weight: 600;
				@include font-size(16px);
				background-color: transparent;
			}

			&:nth-child(n+10) {
				@include rem(padding-left, 26px);
			}
		}

		ol {
			padding-top: 0;
			margin-top: 10px;
		}
	}

	ol {
		ol li ol li {
			@include rem(padding-left, 37px);

			&:before {
				left: -0.75em;
			}
		}

		& > li {
			ol > li {
				@include rem(padding-left, 34px);
			}
		}
	}

	ul ol,
	ol ul,
	ul ul,
	ol ol {
		margin: 0.7em 0;
	}
}

.b-table {
	ul {
		& > li {
			&:before {
				top: calc(0.3em * 1.2);
			}
		}

		ul li {
			&:before {
				top: calc(0.35em * 1.2);
			}

			ul li {
				&:before {
					top: calc(0.375em * 1.2);
				}
			}
		}
	}
}

@mixin reset-counter($counterName) {
	ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ul li ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ol li ul li {
		&:before {
			text-indent: -9999px;
		}
	}
}

.b-accordion {
	@include reset-counter(list-accordion);
}

.b-table {
	@include reset-counter(list-table);
}

.b-tabs {
	@include reset-counter(list-tabs);
}
