.header-1 {
	@include font-size(42px);
	margin-bottom: 39px;
	@include font(800, 'Akrobat');
	color: $my-sin;
	line-height: 1em;
	text-transform: uppercase;

	@include breakpoint(xs) {
		font-size: 35px;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font-size(34px);
	@include rem(margin-bottom, 26px);
	@include font(800, 'Akrobat');
	line-height: 0.911em;
	color: $summer-sky;
	text-transform: uppercase;

	&.dark {
		color: $dark-cerulean;
		@include rem(margin-bottom, 34px);
	}

	&.yellow {
		color: $my-sin;
		@include rem(margin-bottom, 37px);
		@include font(700, 'Akrobat');
		font-weight: 700;
	}


	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
			@include font-size(27px);
		}
	}
}

h2 {
	@extend .header-2;
}

h1.h-icon {
	position: relative;
	display: flex;
	align-items: center;

	div {
		width: 100px;
		height: 100px;
		padding: 0 !important;
		margin-right: 10px;
		flex: 0 0 100px;
	}

	@media (max-width: 375px) {
		@include font-size(29px);
	}

	@media (max-width: 374px) {
		@include font-size(23px);
	}
}

.header-3 {
	@include font-size(28px);
	line-height: 31px;
	margin-bottom: 15px;
	color: $slate-grey;
	@include font(700, 'Akrobat');
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font-size(24px);
	@include rem(line-height, 28px);
	color: $my-sin;
	margin-bottom: 1em;
	@include font(800, 'Akrobat');
	font-weight: 800;
}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font-size(20px);
	@include rem(line-height, 26px);
	text-transform: uppercase;
	color: $dark-cerulean;
	margin-bottom: 1em;
	@include font(700, 'Akrobat');
	font-weight: 700;
}

.header-5--black {
	color: $black-pearl;
}

.header-5--grey {
	color: $slate-grey;
	text-transform: none;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(20px);
	@include font(400, 'Ubuntu');
	font-weight: 400;
	@include rem(line-height, 23px);
	color: $black-pearl;
	margin-bottom: 1em;
	color: $dark-cerulean;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 0;
	}

	&__title {
		margin-left: 12px;
	}
}

.h_special {
	font-weight: bold;
}

.header-fn {
	color: $color-black;
	font-weight: bold;
	@include font-size(18px);
}
