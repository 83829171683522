// figma
@import './figma/tokens-typography.scss';

%text--body,
.text--body {
	@include font-size($base-font-size);
	@include font(400);
	font-style: normal;
}

%arial,
.arial {
	font-family: Arial, Helvetica, sans-serif;
}

%caption {
	@include font-size(16px);
	font-weight: bold;
	color: $base-text-color;
	border-bottom: 2px solid $base-text-color;

	.caption__units,
	.b-caption__units {
		font-weight: normal;
	}
}

p {
	margin-bottom: 1em;
	font-size: 16px;
	line-height: 26px;
	@include font(300, 'Ubuntu');
	@include rem(margin-bottom, 26px);

	&.text-list {
		@include rem(margin-bottom, 13px);
	}
}
