$pagenav--link-width: 30px;
$pagenav--link-height: 30px;

$pagenav--icon-width: $pagenav--link-width / 1.5;
$pagenav--icon-height: $pagenav--link-width - 4;
$pagenav--icon-color: #668FB6;
$pagenav--icon-color-hover: #F2B33F;
$pagenav--icon-color-active: #27BDEE;

$pagenav--btn-bg: $color-eallery;
$pagenav--btn-bg-hover: $color-celeste;
$pagenav--btn-bg-active: $color-dark-gray;

$pagenav--title-width: 250px;
$pagenav--title-color: $color-white;
$pagenav--title-bg: $color-dark-gray;
$pagenav--title-bg-active: $color-dark-gray;

@if ($contrast) {
	$pagenav--icon-color: $color-celeste;
	$pagenav--icon-color-hover: $color-black;
	$pagenav--icon-color-active: $color-carrot-orange;

	$pagenav--btn-bg: $color-eallery;
	$pagenav--btn-bg-hover: $color-celeste;
	$pagenav--btn-bg-active: $color-dark-gray;

	$pagenav--title-color: $color-white;
	$pagenav--title-bg: $color-dark-gray;
	$pagenav--title-bg-active: $color-dark-gray;
}
