.error-page {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	width: 100vw;
	height: 100%;
	// min-width: calc(100% + 32px);
	// left: 50%;
	// transform: translateX(-50%);
	background-position: center bottom;

	// .feature-webp & {
	// 	background-image: url('/images/components/error-page/images/error-bg.jpg');
	// }

	// .feature-no-webp & {
	// 	background-image: url('/images/components/error-page/images/error-bg.jpg');
	// }

	&__inner {
		// color: #fff;
		// text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
		text-align: center;
		@include rem(max-width, 520px);
	}

	&__title {
		font-size: 140px;
		@include font(900);
		margin-top: 0;
	}

	&__text {
		@include font-size(24px);
		line-height: 1.1;
	}

	&__link-wrap {
		@include font-size(18px);
		@include rem(margin-top, 22px);
	}

	&__link {
		color: $link-default-color;

		&:visited {
			color: $link-default-color;
		}

		&:hover,
		&:visited:hover {
			color: $link-hover-color;
			text-decoration: none;
		}

		&:active,
		&:visited:active {
			color: $link-active-color;
		}
	}
}

.body--page-error {
	.content-area {
		padding: 0;
		height: 100%;
	}

	.l-layout-wrapper .l-page {
		flex-direction: row;
		padding: 0;
	}

	.l-page__content {
		padding: 0;
	}

	.l-layout-wrapper--1 .l-header {
		margin-bottom: 0;
	}
}
