/* This is the core CSS of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
}

.tooltipster-content {
	/* prevents an overflow if the user adds padding to the div */
	box-sizing: border-box;
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-ruler {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}

/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 * 
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */

/* .tooltipster-box */

.tooltipster-sidetip .tooltipster-box {
	background: #565656;
	border: 2px solid black;
	border-radius: 4px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
	margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
	margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
	margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
	margin-bottom: 8px;
}

/* .tooltipster-content */

.tooltipster-sidetip .tooltipster-content {
	color: white;
	line-height: 18px;
	padding: 6px 14px;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-sidetip .tooltipster-arrow {
	overflow: hidden;
	position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
	height: 10px;
	/* half the width, for centering */
	margin-left: -10px;
	top: 0;
	width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	right: 0;
	/* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
	been positioned yet */
	top: 0;
	width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	left: 0;
	/* same as .tooltipster-left .tooltipster-arrow */
	top: 0;
	width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
	bottom: 0;
	height: 10px;
	margin-left: -10px;
	width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
	height: 0;
	position: absolute;
	width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-sidetip .tooltipster-arrow-background {
	border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #565656;
	left: 0;
	top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #565656;
	left: -3px;
	top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #565656;
	left: 3px;
	top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #565656;
	left: 0;
	top: -3px;
}

/* .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-border {
	border: 10px solid transparent;
	left: 0;
	top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: black;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
	border-left-color: black;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
	border-right-color: black;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
	border-top-color: black;
}

/* tooltipster-arrow-uncropped */

.tooltipster-sidetip .tooltipster-arrow-uncropped {
	position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
	left: -10px;
}


$tooltips--bg-color: $white;
$tooltips--color: $blue-whale;

@if ($contrast) {
	$tooltips--bg-color: #484848;
	$tooltips--color: $color-white;
}

.b-tooltip {
	display: inline;
	@include webfont-icon($webfont-icon--tooltip-kmg, 'after');
	color: $summer-sky;
	margin: 0 2px;

	&:after {
		position: relative;
		bottom: -0.15em;
		font-size: 0.9em;
		width: 14px;
		height: 14px;
	}

	&:hover,
	&.is-hover {
		color: $link-hover-color;
		cursor: help;
	}

	.b-tooltip__text {
		display: none;
	}
}

.b-tooltips-footnotes,
.b-footnotes,
.b-tooltip-number {
	display: none;
}

.tooltipster-sidetip.tooltipster-tooltips {
	.tooltipster-box {
		border: 1px solid $light-blue;
		background: $tooltips--bg-color;
		color: $tooltips--color;
		@include font-size(12px);
		line-height: 16px;
		@include font-face(300, 'Ubuntu');
		margin-left: 2px;
		border-radius: 13px;
	}

	.tooltipster-content {
		color: $tooltips--color;
	}

	.tooltipster-arrow {
		height: 11px;
		margin-left: -5px;
		width: 22px;

		&:after {
			position: absolute;
		    content: '';
		    display: block;
		    top: 3px;
		    left: -10px;
		    border: 9px solid transparent;
		    border-right: 16px solid $light-blue;
		    z-index: 1;
		}

		&:before {
			position: absolute;
		    content: '';
		    display: block;
		    top: 3px;
		    left: -8px;
		    border: 9px solid transparent;
		    border-right: 16px solid #fff;
		    z-index: 2;
		}
	}

	&.tooltipster-left .tooltipster-arrow,
	&.tooltipster-right .tooltipster-arrow {
		height: 22px;
	    margin-left: -5px;
	    margin-top: -11px;
	    width: 8px;
	}

	.tooltipster-arrow-background {
		border: 11px solid transparent;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $tooltips--bg-color;
		top: 4px;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $tooltips--bg-color;
		left: -4px;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: transparent;
    	left: -2px;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $tooltips--bg-color;
		top: -4px;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}