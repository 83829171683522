$button-width: 33.333%;
$icon-side-margin: 5px;
$button-side-padding: 10px;

// Стили для случая, когда page-nav зафиксирован при скролле
.b-fixed-area {
	@extend %wrapper;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-align: center;
	border-top: 1px solid #668FB6;
	background-color: $color-white;
}

// .b-fixed-area--above-footer {
// 	bottom: $footer-height;
// }

// Стили для случая, когда page-nav находится внизу страницы

.b-now-static-area {
	position: relative;
	width: 100%;
	border: 0;

	&:before {
		content: '';
		background-color: #668FB6;
		display: block;
		height: 1px;
		position: absolute;
		left: 50%;
		width: 100vw;
		margin-left: -50vw;
	}

	.b-pagenav {

		.b-pagenav__centred {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.b-pagenav--bottom {
	.b-pagenav {
		&__centred {
			@include rem(line-height, $pagenav--link-height);
			color: $pagenav--icon-color;
			position: relative;
			@extend %clearfix;
			padding: 21px 62px;
		}

		&__link-content-wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		&__link {
			display: flex;
			align-items: center;
			position: relative;
			@include rem(height, $pagenav--link-height);
			transition: background-color 0.2s ease;
			width: $button-width;
			text-decoration: none;
			color: $pagenav--icon-color;

			&:before {
				@include font-size(14px);
			}

			&:hover,
			&.is-touch {
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					left: 0;
					opacity: 1;
				}

				.b-pagenav__text {
					color: $pagenav--icon-color-hover;
				}

				.b-icon--prev,
				.b-icon--next,
				.b-icon--scrollup {
					border-color: $pagenav--icon-color-hover;
				}
			}

			&:active {
				color: $pagenav--icon-color-active;

				.b-pagenav__text {
					color: $pagenav--icon-color-active;
				}

				.b-icon--prev,
				.b-icon--next,
				.b-icon--scrollup {
					border-color: $pagenav--icon-color-active;
				}
			}

			.b-icon--prev {
				@include rem(margin-right, $icon-side-margin);
			}

			.b-icon--next {
				@include rem(margin-left, $icon-side-margin);
			}
		}

		&__scrollup-wrapper {
			@extend %center-position;
			width: $button-width;

			.b-icon--scrollup {
				@include webfont-icon($webfont-icon--i-arrow-breadcrumbs);
				@include rem(height, 24px);
				width: 24px;
				border: 1px solid #668FB6;
				border-radius: 100%;
				background-color: $color-white;
				position: relative;
    			z-index: 2;

				&::before {
					transform: rotate(-90deg);
					@include font-size(14px);
					margin-top: 3px;
					display: block;
				}
			}

			.b-pagenav__scrollup {
				@extend .b-pagenav__link;
				text-align: center;
				@include rem(width, 42px);
				height: auto;
				cursor: pointer;
				display: flex;
				flex-direction: column-reverse;
				background-color: transparent;
				position: absolute;
				top: -13px;
				left: 50%;
				transform: translateX(-50%);
				color: $pagenav--icon-color;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 12px;
					width: 10px;
					height: 1px;
					background-color: $color-white;
					z-index: 1;
				}

				&::before {
					left: 0;
				}

				&::after {
					right: 0;
				}

				&.is-touch {
					color: $pagenav--btn-bg-hover;

					.b-pagenav__title {
						left: 0;
						opacity: 1;
					}
				}

				.b-pagenav__text--scrollup {
					color: #668FB6;
					font-size: 12px;
					line-height: 18px;
				}
			}
		}

		&__link--prev {
			float: left;
			text-align: left;
			@include rem(padding-left, $icon-side-margin * 2 + $pagenav--icon-width);
			@include rem(padding-right, $button-side-padding);

			.b-icon--prev {
				@include webfont-icon($webfont-icon--i-arrow-breadcrumbs);
				transform: rotate(180deg);
				border: 1px solid #668FB6;
				border-radius: 100%;
				width: 26px;
				height: 26px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		&__link--next {
			float: right;
			text-align: right;
			@include rem(padding-left, $button-side-padding);
			@include rem(padding-right, $icon-side-margin * 2 + $pagenav--icon-width);

			.b-icon--next {
				order: 1;
				@include webfont-icon($webfont-icon--i-arrow-breadcrumbs);
				border: 1px solid #668FB6;
				border-radius: 100%;
				width: 26px;
				height: 26px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.b-pagenav__link-content-wrapper {
				justify-content: flex-end;
			}
		}

		&__title {
			display: block;
			position: absolute;
			width: 100%;
			@include rem(bottom, $pagenav--link-height);
			left: -9999px;
			line-height: $base-line-height;
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			@include font-size(13px);
			margin: 0;
			@include rem(padding, 15px);
			opacity: 0;
			transition: opacity 0.2s ease;

			.b-pagenav-help {
				display: block;
			}
		}

		&__text {
			&--prev,
			&--next {
				font-size: 14px;
				line-height: 18px;
				margin: 0 12px;
				max-width: 52%;
				width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		// Стили, применяемые к неактивным кнопкам пагинации
		&__link--disabled {
			opacity: 0.3;
			pointer-events: none;
			cursor: default;
			outline: 0;

			&:hover {

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg;
			}
		}
	}
}

.b-pagenav--animate-links {
	.b-pagenav {
		&__link-content-wrapper {
			height: 100%;
			position: relative;
			overflow: visible;
		}

		&__scrollup-wrapper {
			.b-pagenav__scrollup {
				height: 100%;
			}

			.b-icon--scrollup {
				@include rem(bottom, 20px);
			}
		}

		&__direction {
			display: block;
			@include font-size(25px);
			position: relative;
			@include rem(top, 13px);
			transition: all 0.4s ease-in-out;
		}

		&__text {
			@include rem(bottom, -25px);
			transition: all 0.4s ease-in-out;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
			position: absolute;
		}

		&__text--scrollup {
			left: 0;
			bottom: 0;
		}

		&__link {
			@include rem(height, $pagenav--link-height * 2);
			overflow: hidden;

			.b-icon--prev {
				@include rem(left, -45px);
				transition: all 0.4s ease-in-out;
			}

			.b-icon--next {
				@include rem(right, -45px);
				transition: all 0.4s ease-in-out;
			}

			&:hover {
				.b-icon--prev {
					@include rem(left, -20px);
				}

				.b-icon--next {
					@include rem(right, -20px);
				}

				.b-pagenav__direction {
					@include font-size(16px);
					@include rem(top, 2px);
				}

				.b-pagenav__text {
					bottom: 0;
				}

				.b-pagenav__text--next {
					left: 0;
				}
			}
		}
	}
}

.b-pagenav--loading {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.pagenav--hidden-up {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.b-pagenav--expandable {
	.b-pagenav {
		&__link {
			width: 50%;
		}

		&__expandable-button {
			@include webfont-icon($webfont-icon--menu);
			background-color: $pagenav--btn-bg;
			width: 40px;
			border-radius: 50%;
			transform: translateY(17px);
			color: $color-taupe-gray;
			@include rem(padding-bottom, 15px);

			&:hover,
			&:active {
				color: $color-taupe-gray;
			}
		}

		.b-icon {
			position: absolute;
		}

		&__link--disabled {
			opacity: 1;
			.b-icon {
				display: none;
			}
		}
	}

	.is-open {
		transition: all 0.4s ease-in-out;
		bottom: 0;
	}

	.is-close {
		transition: all 0.4s ease-in-out;
		@include rem(bottom, -24px);
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.b-pagenav--bottom {
			.b-pagenav {
				&__centred {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
	@include breakpoint(sm) {
		.b-pagenav--animate-links {
			.b-pagenav {
				&__scrollup-wrapper {
					.b-pagenav__scrollup {
						height: 100%;
					}

					.b-icon--scrollup {
						bottom: 0;
					}
				}

				&__direction {
					display: none;
				}

				&__text {
					display: none;
				}

				&__link {
					@include rem(height, $pagenav--link-height);
					.b-icon--prev {
						@include rem(left, 10px);
					}

					.b-icon--next {
						@include rem(right, 10px);
					}
				}
			}
		}

		.b-pagenav--bottom {
			.b-pagenav {

				&__centred {
					padding: 5px 0;
				}

				&__link {
					&--prev {
						padding-left: 16px;
					}
					&--next {
						padding-right: 16px;
					}
				}
			}
		}
	}

	@include breakpoint(xs) {
		.b-pagenav--bottom {
			.b-pagenav {
				&__text {
					display: none;
					&--scrollup {
						display: block;
					}
				}
			}
		}
	}
}
