blockquote {
	position: relative;
	background-color: $white;
	@include rem(padding, 8px 16px 35px 80px);
	border-bottom: 4px solid $color-navy-blue;
	border: none;
	@include rem(margin-bottom, 50px);
	@include rem(border-width, 2px);
	@include rem(border-radius, 25px);
	border-style: solid;
	border-color: $blizzard-blue;

	.text {
		@include font(400, 'UbuntuItalic');
		color: $summer-sky;
		@include font-size(16px);
		@include rem(line-height, 26px);
		@include rem(padding-top, 31px);
		@include rem(margin-bottom, 16px);
		
		

		&:before {
			content: "\"";
			position: absolute;
			@include svg(100%, 100%, "/images/icons/politic.svg");
			content: '';
			@include rem(top, -28px);
			left: 90%;
			@include rem(width, 42px);
 			@include rem(height, 36px);
 			background-color: $white;

 			@include breakpoint(md) {
 				display: none;
 			}
		}
	}

	.person {
		text-align: right;

		&__name {
			display: block;
			text-transform: uppercase;
			color: $black-pearl;
			@include font(700, 'Akrobat');
			@include font-size(20px);
			@include rem(line-height, 26px);
			@include rem(margin-bottom, 3px);
		}

		&__position {
			display: block;
			color: rgba($black-pearl, 0.5);
			@include font-size(16px);
			@include rem(line-height, 26px);
			@include font(300, 'Ubuntu');
		}

		&__company {
			display: block;
		}
	}
}

.quote--photo {
	display: flex;
	padding: 0;
	border: none;
	@include rem(padding, 30px 16px 30px 80px);
	@include rem(margin-bottom, 59px);
	

	@include breakpoint(md) {
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
	}

	.quote__photo {
		flex-shrink: 0;
		@include rem(height, 240px);
		@include rem(width, 240px);
		border-radius: 50%;

		@include breakpoint(md) {
			@include rem(margin-bottom, 25px);
		}
		

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.quote__content {
		position: relative;
		@include rem(padding, 0 10px 0px 39px);

		& .text {
			@include rem(border-top-width, 2px);
			@include rem(margin-bottom, 30px);
			border-top-style: solid;
			border-top-color: $blizzard-blue;
			position: relative;
			

			&:before {
				@include rem(top, -20px);
				left: 100%;
			}
		}
	}
}

@include breakpoint(sm) {
	blockquote {
		padding: 24px 16px 24px 40px;

		.text {
			&:before {
				left: -28px;
			}
		}
	}

	.quote--photo {
		display: block;
		padding: 24px 16px 24px 40px;

		.quote__photo {
			margin: 0 auto;
			@include rem(margin-bottom, 25px);
		}

		.quote__content {
			position: relative;
			padding: 0;
		}
	}
}
