.figure-buttons {
	position: relative;
	text-align: right;
	margin-top: 19px;
	display: flex;
	

	.button + .button {
		margin-left: 13px;
		margin-right: 28px;
	}

	.button {
		padding: 0;
		text-decoration: none;
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(sm) {
		.figure-buttons {
			margin-top: 10px;
		}

		.figure-buttons .button {
			font-weight: 300;
			font-size: 16px;
			line-height: 20px;
			text-align: left;
			padding: 15px 0 15px 30px;
			color: #F6F6F6;
			background: #27BDEE;
			border-radius: 50px;
			position: relative;
			width: 100%;
			text-decoration: none;

			@include webfont-icon($webfont-icon--i-button-xls);

			&:before {
				border-radius: 100%;
				background-color: $color-white;
				color: #27BDEE;
				@include font-size(22px);
				position: absolute;
				font-size: 22px;
				right: 1px;
				top: 50%;
				transform: translateY(-50%);
				width: 44px;
				height: 44px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}

			&:active {
				background: #004586;
				&:before {
					color: #004586;
				}
			}
		}
	}
}
