.graphic {
	
	@include rem(margin-bottom, 52px);

	&__img {
		@extend %collapse--top;
	}

	.highcharts-exporting-group {
		display: none; //убрал по замечанию
	}

	figcaption {

		@include font-size(16px);
		@include rem(line-height, 24px);
		@include font(700, 'Akrobat');
		color: $base-text-color;
		margin-bottom: 20px;
		text-transform: uppercase;

	}

	.figure-buttons {
		justify-content: flex-end;
	}

	&--margin {
		margin-top: 44px;
	}
}


.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
