$title--text-color: $color-white;
$title--link-color: $color-white;
$title--link-color--hover: $my-sin;
$title--link-color--active: $summer-sky;
// $title--link-color--visited: $figma-color--select-color;

@if ($contrast) {
	$title--text-color: $color-white;
}

.b-report-title {
	@include font-size(20px);
	@include rem(line-height, 23px);
	@include rem(letter-spacing, 0.1px);
	text-transform: uppercase;
	color: $title--link-color;

	&__link {
		color: $title--link-color;
		text-decoration: none;

		&:visited {
			color: $title--link-color;
		}

		&:hover,
		&:visited:hover {
			color: $title--link-color--hover;
		}

		&:active,
		&:visited:active {
			color: $title--link-color--active;
		}
	}

	@if ($responsive) {

		@include breakpoint(md) {
			@include font-size(18px);
			@include rem(line-height, 21px);
		}

		@include breakpoint(sm) {
			@include font-size(10px);
			@include rem(line-height, 11px);
		}
	}
}
