$extendable-input-width: 366px;
$extendable-input-border-color: $color-white;

.b-search {
	&.b-search--extendable {
		position: relative;
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			@include rem(width, $extendable-input-width);
			height: 100%;
			z-index: 10;
			position: absolute;
			right: 40px;
		}

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 20px);
			@include rem(left, 10px);
			cursor: text;
			@include font-size(18px);
			@include rem(line-height, 22px);
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			@include rem(padding-right, 50px);
			@include rem(padding-left, 10px);
			margin-top: 0;
			background-color: $dark-cerulean;
			border: 1px solid $extendable-input-border-color;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			color: $color-white;
			@include font-size(18px);
			@include rem(line-height, 22px);
		}

		.b-search__button {
			display: block;
			padding: 0;
			background-color: transparent;
			color: $search--btn-icon;
			@include webfont-icon($webfont-icon--i-search);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

			&:before {
				display: block;
				@include font-size(34px);
			}

			&:hover {
				color: $search--btn-icon-hover;
			}

			&:active,
			&.is-expand {
				color: $search--btn-icon-active;
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}


		@if ($responsive) {

			@include breakpoint(md) {
				.b-search__button {

					&:before {
						@include font-size(24px);
					}
				}
			}

			@include breakpoint(sm) {
				.b-search__button {
					color: $dark-cerulean;

					&.is-expand {
						display: none;
					}
				}

				.b-search__wrapper-input {
					left: 0;
					right: auto;
					height: 28px;
				}

				.b-search__input-container:before {
					content: '';
					position: absolute;
					@include rem(height, 1px);
					bottom: 0;
					left: 24px;
					right: 0;
					background-color: $dark-cerulean;
				}

				.b-search__input {
					background-color: $color-solitude;
					border: none;
					@include rem(padding, 0 6px 0 30px);
					@include font-size(14px);
					color: $dark-cerulean;
				}

				.b-search__label {
					@include rem(left, 40px);
					@include font(300);
					@include font-size(14px);
					color: $dark-cerulean;
					opacity: .7;
					left: 29px;
				}

				.search__additional-buttons {
					left: 0;
					right: auto;
				}

				.b-search__button-close {
					padding: 0;
					@include font-size(16px);
				}
			}
		}
	}
}