$bc-link-color: #668FB6;
$bc-link-color-hover: #F2B33F;
$bc-link-color-active: #27BDEE;
$bc-link-color-visited: $bc-link-color;

$bc-home-icon-color: #668FB6;
$bc-home-icon-color-hover: #F2B33F;
$bc-home-icon-color-active: #27BDEE;

$bc-item-color: #004586;
$bc-dash-color: #668FB6;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: $link-default-color;
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;

	$bc-home-icon-color: #444444;
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;

	$bc-item-color: black;
	$bc-dash-color: $color-ironside-gray;

	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: $link-default-color;
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	font-size: 12px;
	line-height: 15px;

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;
				text-decoration: none;
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;

		& + .breadcrumbs__item {
			@include rem(padding-left, 24px);
			@include webfont-icon($webfont-icon--i-arrow-breadcrumbs, after);

			&:after {
				position: absolute;
				top: 4px;
				width: 10px;
				font-size: 10px;
				color: $bc-dash-color;
				left: 9px;
				display: block;
			}
		}

		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}

	&__link {
		color: $bc-link-color;

		&:visited {
			color: $bc-link-color-visited;
		}

		&:hover {
			color: $bc-link-color-hover;
			text-decoration: none;
		}

		&:active {
			color: $bc-link-color-active;
		}
	}

	&__link--home {
		position: relative;
		display: flex;
		color: $bc-home-icon-color;
		font-size: 0;
		@include webfont-icon($webfont-icon--i-home);
		text-decoration: none;

		&:before {
			@include font-size(16px);
		}

		&:hover {
			color: $bc-home-icon-color-hover;
		}

		&:active {
			color: $bc-home-icon-color-active;
		}
	}
}
