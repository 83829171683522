$bod_bod-animated-blocks--content-bg: $color-eallery;
$bod_bod-animated-blocks--content-arrow: $color-white;
$bod_bod-animated-blocks--name-color: #27BDEE;

.b-bod--animated-blocks {
	p {
		margin-top: 0;
	}

	%content-arrow,
	.content-arrow {
		content: '';
		width: 0;
		height: 0;
		border-left-color: transparent;
		border-right-color: transparent;
		border-top-color: #0065B2;
		border-left-style: solid;
		border-right-style: solid;
		border-top-style: solid;
		@include rem(border-left-width, 20px);
		@include rem(border-right-width, 20px);
		@include rem(border-top-width, 15px);
		position: absolute;
		top: 0;
	}

	.b-bod-main {
		position: relative;
		text-align: center;
		cursor: pointer;

		&__photo {
			// @include rem(width, 305px);
			// @include rem(height, 172px);
			width: 100%;
			padding-bottom: calc(172 / 305 * 100%);
			position: relative;
			right: 0;
			left: 0;
			margin: auto;

			img {
				// @include rem(width, 270px);
				// @include rem(height, 152px);
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		&.is-arrowed {
			&:before {
				@extend .content-arrow;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 100%;
				z-index: 1;
				margin-top: 45px;
			}
		}

		&.is-static {
			cursor: default;
		}

		&:hover,
		&.is-expand,
		&.is-static {
			.b-bod-main__photo {
				width: calc(305 / 270 * 100%);
				margin-left: calc(((305 / 270 * 100%) - 100%) / -2);
				margin-right: calc(((305 / 270 * 100%) - 100%) / -2);

				img {
					// @include rem(width, 305px);
					// @include rem(height, 172px);
					width: calc(305 / 280 * 100%);
					height: calc(172 / 162 * 100%);
					transition: all 0.5s;
				}
			}

			&:focus {
				outline: 0;
			}
		}

		&__name {
			@include font-size(20px);
			color: $bod_bod-animated-blocks--name-color;
			margin-top: 10px;
    		margin-bottom: 10px;
    		text-transform: none;
		}

		&__post {
			@include font-size(16px);
			margin-top: 0;
		    color: #0065B2;
		    line-height: 18px;
		}
	}

	.b-bod-content {
		width: 100%;
		border: 2px solid #D9E6F1;
		border-radius: 25px;
		position: relative;
		@include rem(padding, 25px);
		display: none;
		margin-top: 44px;

		&.is-static {
			display: block;
		}
	}
}
