.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		width: 34px;
		height: 34px;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: block;
			// @include font-size($buttons-panel--trigger-icon-size);
			z-index: 2;
			position: relative;
		}

		&::after {
			content: '';
			position: absolute;
			top: -9px;
			left: -9px;
			width: calc(100% + 18px);
			height: calc(100% + 18px);
			background-color: #003364;
			border-radius: 15px 15px 0px 0px;
			z-index: 1;
			opacity: 0;
		}

		&:hover {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&.is-expand {
			color: $summer-sky;
			@include webfont-icon($webfont-icon--i-close);
			&::after {
				opacity: 1;
			}
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: -9px;
		margin-top: 9px;
		z-index: 20;
		background-color: #003364;
		border-radius: 10px 0 10px 10px;
		overflow: hidden;
		padding: 12px 0px;
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;
		position: relative;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__link {
		white-space: nowrap;
		padding: 10px 23px 10px 69px;

		&:before {
			@include rem(left, 35px);
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		@include webfont-icon($webfont-icon--i-download);
		&::before {
			@include font-size(32px);
		}
		&.is-expand {
			@include webfont-icon($webfont-icon--i-close);
			&::before {
				@include font-size(28px);
			}
		}
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		.b-buttons-panel__trigger-controller {
			display: none;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--i-pdf-double-page);
		&::before {
			font-size: 20px;
		}
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--i-pdf-page);
		&::before {
			font-size: 20px;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--tools {

	.b-buttons-panel__trigger {
		@include webfont-icon($webfont-icon--i-gear);
		&::before {
			@include font-size(34px);
		}

		&.is-expand {
			@include webfont-icon($webfont-icon--i-close);
			&::before {
				@include font-size(28px);
			}
		}
	}
}

@if ($responsive) {
	.b-buttons-panel--in-column {

		@include breakpoint(md) {

			&.b-buttons-panel--tools {
				.b-buttons-panel__trigger {
					@include rem(width, 26px);
					@include rem(height, 26px);

					&:before {
						@include font-size(26px);
					}

					&.is-expand:before {
						@include font-size(19px);
					}
				}
			}

			&.b-buttons-panel--download-pdf {
				.b-buttons-panel__trigger {
					@include rem(width, 26px);
					@include rem(height, 24px);

					&:before {
						@include font-size(24px);
					}

					&.is-expand:before {
						@include font-size(19px);
					}
				}
			}
		}

		@include breakpoint(sm) {

			&.b-buttons-panel--download-pdf {
				.b-buttons-panel__trigger {
					@include rem(width, 25px);
					@include rem(height, 22px);
					color: $dark-cerulean;

					&:before {
						@include font-size(22px);
					}

					&.is-expand:before {
						@include font-size(16px);
						color: $summer-sky;
					}
				}

				.b-buttons-panel__link {
					@include rem(padding, 10px 16px 10px 57px);

					&:before {
						@include rem(left, 28px);
					}
				}

				.b-buttons-panel__list {
					@include rem(left, auto);
					@include rem(right, -9px);
					border-radius: 10px 0  10px 10px;
					min-width: 175px;
				}
			}
		}

		@include breakpoint(xs) {
			
			.b-buttons-panel__sublist--share {
				width: auto;
				// height: auto;
			}

			.b-buttons-panel__sublist-link {
				float: none;
			}
		}
	}
}