$mobile-menu_vertical-accordeon--font-size: 15px;
$mobile-menu_vertical-accordeon--button-width: 30px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 30px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 3px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 3px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 20px;

$mobile-menu_vertical-accordeon--panel-bg: $color-solitude;
$mobile-menu_vertical-accordeon--link-color: $dark-cerulean;
$mobile-menu_vertical-accordeon--link-color-hover: $color-black;
$mobile-menu_vertical-accordeon--link-color-active: orange;
$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
$mobile-menu_vertical-accordeon--controller-color-active: orange;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: $color-ironside-gray;
$mobile-menu_vertical-accordeon--button-color-hover: $color-black;
$mobile-menu_vertical-accordeon--button-color-active: orange;
$mobile-menu_vertical-accordeon--link-lvl1-bg: transparent;
$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;

@if ($contrast) {
	$mobile-menu_vertical-accordeon--panel-bg: $color-eallery;
	$mobile-menu_vertical-accordeon--link-color: $base-text-color;
	$mobile-menu_vertical-accordeon--link-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--link-color-active: orange;
	$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
	$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--controller-color-active: orange;
	$mobile-menu_vertical-accordeon--button-bg: transparent;
	$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
	$mobile-menu_vertical-accordeon--button-bg-active: transparent;
	$mobile-menu_vertical-accordeon--button-color: $color-ironside-gray;
	$mobile-menu_vertical-accordeon--button-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--button-color-active: orange;
	$mobile-menu_vertical-accordeon--link-lvl1-bg: $color-light-gray;
	$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
	$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;
}

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	@include rem(width, 26px);
	@include rem(height, 19px);

	.b-mobile-menu__burger {
		z-index: 10;
		position: relative;
		@include webfont-icon($webfont-icon--i-menu);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include rem(width, 26px);
		@include rem(height, 19px);
		@include rem(padding, 0);
		@include rem(margin, 0);
		color: $color-white;
		transition: all .3s ease;
		text-decoration: none;
		background-color: transparent;

		&:hover {
			text-decoration: none;
		}

		&:active {
			color: $summer-sky;
		}

		&::before {
			@include font-size(19px);
		}

		&.is-expand {
			@include webfont-icon($webfont-icon--i-close);
			z-index: 500;
			top: 0!important;
			right: 0!important;

			&:before {
				color: $summer-sky;
			}
		}

		.b-mobile-menu__burger-ingredients {
			display: none;
			// position: absolute;
			// top: 0;
			// bottom: 0;
			// left: 0;
			// right: 0;
			// height: $mobile-menu_vertical-accordeon--bar-thickness;
			// width: $mobile-menu_vertical-accordeon--bar-width;
			// margin: auto;

			// &:before,
			// &:after {
			// 	content: '';
			// 	position: absolute;
			// 	display: block;
			// 	left: 0;
			// 	width: 100%;
			// 	height: $mobile-menu_vertical-accordeon--bar-thickness;
			// 	background-color: $mobile-menu_vertical-accordeon--button-color;
			// 	transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
			// 	transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
			// 	will-change: transform;
			// }

			// &:before {
			// 	top: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
			// 	/* autoprefixer: off */
			// 	transition-property: top, transform;
			// }

			// &:after {
			// 	bottom: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
			// 	/* autoprefixer: off */
			// 	transition-property: bottom, transform;
			// }
		}

		// .b-mobile-menu__burger-ingredients-middle {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	background-color: $mobile-menu_vertical-accordeon--button-color;
		// 	transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
		// 	will-change: transform;
		// }

		// &.is-expand {
		// 	position: fixed;
		// 	z-index: 500;
		// 	background: none;

		// 	.b-mobile-menu__burger-ingredients-middle {
		// 		opacity: 0;
		// 	}

		// 	.b-mobile-menu__burger-ingredients {
		// 		&:before,
		// 		&:after {
		// 			transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
		// 		}

		// 		&:before {
		// 			top: 0;
		// 			transform: rotate(45deg);
		// 		}

		// 		&:after {
		// 			bottom: 0;
		// 			transform: rotate(-45deg);
		// 		}
		// 	}
		// }
	}

	.b-mobile-menu__panel {
		display: none;
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 100;
		background-color: $mobile-menu_vertical-accordeon--panel-bg;
		@include rem(margin-top, 45px);
		@include rem(padding, 0 16px);
		overflow: auto;

		&::-webkit-scrollbar {
			background: transparent;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 50px;
			background-color: #A4AEB6;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.b-mobile-menu__inner {
		display: flex;
		@include rem(padding, 24px 0);
		align-items: center;
		justify-content: space-between;
	}

	.b-mobile-menu__item {
		display: block;
		vertical-align: top;
		margin-bottom: 15px;
	}

	.b-mobile-menu__panel-item--pdf {
		@include rem(margin, 0 18.5px);
		margin-right: 0;
	}

	.b-mobile-menu__panel-item--lang {
		@include rem(margin, 0 18.5px);
		margin-right: 0;
	}

	.b-mobile-menu__panel-item--search {
		@include rem(height, 24px);
		margin-right: auto;
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}

		&--menu {
			width: 100%;
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}
	}

	.b-nav__item--lvl1 + .b-nav__item--lvl1 {
		margin-top: 1px;
	}

	.b-nav__link {
		display: block;
		@include rem(padding-right, 22px);
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
		color: $mobile-menu_vertical-accordeon--link-color;
		@include font-size($mobile-menu_vertical-accordeon--font-size);
		text-decoration: none;
		@include font(400, 'Akrobat');
		line-height: 1;

		span {
			line-height: 1.4;
		}

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
			text-decoration: underline;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}

		&.current {
			@include font(600);
		}
	}

	.b-nav__controller {
		position: absolute;
		@include rem(width, 44px);
		@include font-size(14px);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		top: 0;
		right: -16px;
		margin: auto 0;
		bottom: 0;
		cursor: pointer;
		@include webfont-icon($webfont-icon--i-arrow-breadcrumbs);
		color: $dark-cerulean;

		&:before {
			transform: rotate(90deg);
		}

		&.is-expand:before {
			transform: rotate(270deg);
		}
	}

	.b-nav__item--lvl1 .b-nav__controller {
		@include rem(padding-top, 22.5px);
	}

	.b-nav__item--lvl2 .b-nav__controller,
	.b-nav__item--lvl3 .b-nav__controller,
	.b-nav__item--lvl4 .b-nav__controller {
		@include rem(padding-top, 9px);
	}

	.b-nav__link--lvl1 {
		background-color: $mobile-menu_vertical-accordeon--link-lvl1-bg;
		color: $mobile-menu_vertical-accordeon--link-color;
		text-transform: uppercase;
		@include font(700, 'Akrobat');
		@include font-size(20px);
		@include rem(line-height, 24px);
		@include rem(padding-top, 15px);
		@include rem(padding-bottom, 15px);
	}

	.b-nav__link-wrapper {
		position: relative;
	}

	.b-nav__link--lvl2 {
		@include font(400, 'Ubuntu');
		@include font-size(16px);
		@include rem(line-height, 20px);
		color: $bright-grey;

		&.is-expand {
			@include font(500, 'Ubuntu');
			color: $black-pearl;
			@include rem(padding-bottom, 15px);
		}
	}

	.b-nav__link--lvl3 {
		@include rem(padding-left, 12px);
		@include font(400, 'Ubuntu');
		@include font-size(14px);
		color: $bright-grey;

		&.is-expand {
			@include font(500, 'Ubuntu');
			color: $black-pearl;
			@include rem(padding-bottom, 10px);
		}
	}

	.b-nav__link--lvl4 {
		@include rem(padding-left, 32px);
		@include font(400, 'Ubuntu');
		@include font-size(14px);
		color: $bright-grey;

		&.is-expand {
			@include font(500, 'Ubuntu');
			color: $black-pearl;
			@include rem(padding-bottom, 7px);
		}
	}

	.b-nav__list--lvl3.is-expand,
	.b-nav__list--lvl4.is-expand {
		padding-bottom: 10px;
	} 

	.b-nav__item--lvl2,
	.b-nav__item--lvl3 {
		.b-nav__controller.is-expand {
			color: $my-sin;
		}
	}
}
